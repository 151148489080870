import React, { useEffect, useCallback, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Typography,
  Tooltip,
  Button,
  Hidden,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/styles";
import { AutocompleteMaterial } from "../../../components/AutocompleteMaterial";
import MultiSelect from "../../../components/MultiSelect";
import useLanguage from "../../../languages";
import { SELECTS_OPTIONS_CABINET } from "./CabinetAutoComplete";
import TextInput from "../../../components/TextInput";
import { useStore } from "../../../store";
import { useStyles } from "./style";
import { REACT_APP_API_URL } from "../../../constants/selectConstants";
import { isFatfCountry } from "../../../api/api";

const Helper = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    height: 16,
    width: 16,
    borderColor: "rgba(126, 159, 193, .5)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    padding: 0,
    color: theme.palette.text.secondary,
    background: "white !important",
  },
  label: {
    fontSize: 10,
    lineHeight: 1,
  },
}))(Button);

export default observer(
  ({
    handleChangeSelect,
    profile,
    handleChangeAutoComplete,
    handleChange,
    handleChangeChecked,
    handleChangeDate,
    isValidation,
    handleChangeProfileProp,
    notificationManager
  }) => {
    const getText = useLanguage();
    const classes = useStyles();
    const store = useStore();
    const canvasTextRef = useRef(null);
    const canvasDateRef = useRef(null);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const convasTextDraw = useCallback(async () => {
      if (!profile.signature_text) return

      const canvas = canvasTextRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.textAlign = "center";
      ctx.textBaseline = "center";
      ctx.font = "48px Corinthia";

      await new Promise((resolve, reject) => {
        setTimeout(() => resolve(
          ctx.fillText(profile?.signature_text || "", canvas.width / 2, canvas.height / 2)
        ), 100);
      })

      function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(",");
        const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i += 1) ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
      }

      const image = new Image();
      image.src = canvas.toDataURL();
      const formData = new FormData();
      const file = DataURIToBlob(canvas.toDataURL());
      formData.append("signature_photo", file, "photo.jpg");

      const result = await fetch(`https://us.altaclub.vc/api/v1/investors/fill_personal_data/`, {
        method: "PATCH",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_access")}`,
        },
      });
    }, [profile.signature_text]);

    useEffect(() => {
      if (!profile.profile_signed_date) return

      fetch(`https://us.altaclub.vc/api/v1/investors/fill_personal_data/`, {
        method: "PATCH",
        body: JSON.stringify({ profile_signed_date: profile.profile_signed_date }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_access")}`,
          'Content-type': 'application/json'
        },
      })
    }, [profile.profile_signed_date])

    const convasDateDraw = useCallback(() => {
      const canvas = canvasDateRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.textAlign = "center";
      ctx.textBaseline = "center";
      ctx.font = "48px Corinthia";
      ctx.fillText(profile?.profile_signed_date || "", canvas.width / 2, canvas.height / 2);
    }, [profile.profile_signed_date]);

    useEffect(() => {
      convasTextDraw();
    }, [profile.signature_text]);

    useEffect(() => {
      convasDateDraw();
    }, [profile.profile_signed_date]);

    useEffect(() => {
      (async () => {
        if (profile.bank_country) {
          const fatfCountryResponse = await isFatfCountry(profile.bank_country).then(
            (res) => res.json()
          )

          const isFatfApproved = fatfCountryResponse.is_fatf_country ? "YES" : "NO"

          handleChangeProfileProp("is_wiring_bank_fatf_approved", isFatfApproved)

          if (!fatfCountryResponse.is_fatf_country) {
            notificationManager.error("Your bank is not FATF approved")
          }
        }
      })()
    }, [profile.bank_country])

    const BLOCKED_COUNTRIES = ['RU', 'BY'] // РФ, Белоруссия
    const isBlockedCountry = (country) => BLOCKED_COUNTRIES.includes(country)

    const getWiringBankFatfApproved = (isFatfApproved) => {
      if (isFatfApproved && isFatfApproved.length) {
        return isFatfApproved.toLowerCase() === 'true' || isFatfApproved.toLowerCase() === 'yes'
      }

      return "Please, fill the company country"
    }

    return (
      <div className={classes.container}>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.last_name_en}
            onChange={handleChange("last_name_en")}
            caption={getText("pages.cabinet.memberInformation.surname")}
            data-castil="last_name_en"
            error={isValidation.last_name_en}
            helperMessage="Empty input"
            required
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.first_name_en}
            onChange={handleChange("first_name_en")}
            caption={getText("pages.cabinet.memberInformation.forename")}
            data-castil="first_name_en"
            error={isValidation.first_name_en}
            helperMessage="Empty input"
            required
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.middle_name_en}
            onChange={handleChange("middle_name_en")}
            caption={getText("pages.cabinet.memberInformation.patronymic")}
          />
        </Box>
        <Box mt={2}>
          <span className={classes.datePickerLabel}>{getText("pages.cabinet.memberInformation.dateOfBirth")}*</span>
          <DatePicker
            className={classes.pickerContainerElement}
            onChange={handleChangeDate("date_of_birth")}
            format="DD/MM/YYYY"
            animateYearScrolling
            value={profile?.date_of_birth}
            data-castil="date_of_birth"
            error={isValidation.date_of_birth}
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.passport_number}
            onChange={handleChange("passport_number")}
            caption={getText("pages.cabinet.memberInformation.passportNo")}
            data-castil="passport_number"
            error={isValidation.passport_number}
            helperMessage="Empty input"
            required
          />
        </Box>
        <Box mt={2}>
          <span className={classes.datePickerLabel}>{getText("pages.cabinet.memberInformation.expiryDate")}*</span>
          <DatePicker
            className={classes.pickerContainerElement}
            onChange={handleChangeDate("date_of_passport_issue")}
            format="DD/MM/YYYY"
            animateYearScrolling
            value={profile?.date_of_passport_issue}
            data-castil="date_of_passport_issue"
            error={isValidation.date_of_passport_issue}
          />
        </Box>
        <Box mt={2}>
          <AutocompleteMaterial
            flag
            options={store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
            text="citizenship"
            title="Citizenship"
            value={profile.citizenship}
            onChange={handleChangeAutoComplete}
            dataattribute="citizenship"
            error={isValidation.citizenship}
            required
          />
        </Box>
        <Box mt={2}>
          <AutocompleteMaterial
            flag
            options={
              store.citizenships_en
                .filter(((code) => !isBlockedCountry(code)))
                .map((code) => ({ label: getText(`countries.${code}`), value: code }))
            }
            text="residence_country"
            title="Residence country"
            value={profile.residence_country}
            onChange={handleChangeAutoComplete}
            dataattribute="residence_country"
            error={isValidation.residence_country}
            required
          />
        </Box>
        <Box className={classes.bottomLine} />
        <Box mt={2}>
          <FormLabel className={classes.input} component="legend">
            <Typography className={classes.labelInput}>
              {getText("pages.cabinet.usPerson.usPersonTitle")}
            </Typography>
          </FormLabel>

          <RadioGroup
            aria-label="is_us_person"
            className={classes.radioGroup}
            value={profile?.is_us_person}
            dataattribute="is_us_person"
            onChange={handleChange("is_us_person")}
            error={isValidation.is_us_person}
            required
          >
            <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
          </RadioGroup>

          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Tooltip placement="right" title={getText("pages.cabinet.tooltips.usPerson")}>
              <Box mt={2}>{getText("pages.cabinet.tooltips.titleUsPerson")}</Box>
            </Tooltip>
            <Tooltip placement="right" title={getText("pages.cabinet.tooltips.notUsPerson")}>
              <Box mt={2}>{getText("pages.cabinet.tooltips.titleNonUsperson")}</Box>
            </Tooltip>
          </Box>
        </Box>
        {profile.is_us_person === "YES" && (
          <>
            <Box className={classes.bottomLine} />
            <Box mt={2}>
              <AutocompleteMaterial
                options={SELECTS_OPTIONS_CABINET.is_us_federal_taxes.map((el) => ({
                  label: getText(`${el.label}`),
                  value: el.value,
                }))}
                text="is_us_federal_taxes"
                title="pages.cabinet.usIncomeTax.usIncomeTaxTitle"
                value={profile?.is_us_federal_taxes}
                onChange={handleChangeAutoComplete}
                dataattribute="is_us_federal_taxes"
                error={isValidation.is_us_federal_taxes}
                required
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.social_security_number}
                onChange={handleChange("social_security_number")}
                caption={getText("pages.cabinet.usIncomeTax.securityNumber")}
                data-castil="social_security_number"
                error={isValidation.social_security_number}
                helperMessage="Empty input"
                required
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.green_card_number}
                onChange={handleChange("green_card_number")}
                caption={getText("pages.cabinet.usIncomeTax.greenCard")}
              />
            </Box>
          </>
        )}
        {profile.is_us_person === "NO" && (
          <>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.tingin}
                onChange={handleChange("tingin")}
                caption={getText("pages.cabinet.tingin")}
                data-castil="tingin"
                error={isValidation.tingin}
                helperMessage="Empty input"
                required
              />
            </Box>
          </>
        )}
        <Box className={classes.bottomLine} />
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.residence_address}
            onChange={handleChange("residence_address")}
            caption={getText("pages.cabinet.residenceAddress")}
            data-castil="residence_address"
            error={isValidation.residence_address}
            helperMessage="Empty input"
            required
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.mailing_address}
            onChange={handleChange("mailing_address")}
            caption={getText("pages.cabinet.mailingAddress")}
          />
        </Box>
        <Box className={classes.bottomLine} />
        <Box mt={2}>
          <FormControlLabel
            className={classes.checkboxTitle}
            label={<p className={classes.checkboxTitle}>{getText("pages.cabinet.electronicSigning")}</p>}
            control={
              <Checkbox
                checked={profile.agree_to_electronic_signing === "YES"}
                onChange={handleChangeChecked("agree_to_electronic_signing")}
                color="primary"
                className={isValidation.agree_to_electronic_signing ? classes.checkboxError : classes.checkbox}
                data-castil="agree_to_electronic_signing"
                error={isValidation.agree_to_electronic_signing}
              />
            }
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.investment_planned_amount}
            onChange={handleChange("investment_planned_amount")}
            caption={getText("pages.cabinet.investmentPlanned")}
            data-castil="investment_planned_amount"
            error={isValidation.investment_planned_amount}
            helperMessage="Empty input"
            required
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.salary}
            onChange={handleChange("salary")}
            caption={getText("pages.cabinet.salary")}
            data-castil="salary"
            error={isValidation.salary}
            helperMessage="Empty input"
            required
          />
        </Box>
        <Box className={classes.bottomLine} />
        <Typography variant="h5">Bank information</Typography>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.bank_name}
            onChange={handleChange("bank_name")}
            caption={getText("pages.cabinet.bankName")}
            data-castil="bank_name"
            error={isValidation.bank_name}
            helperMessage="Empty input"
            required
          />
        </Box>
        <Box mt={2}>
          <AutocompleteMaterial
            flag
            options={store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
            text="bank_country"
            title={getText("pages.cabinet.bankCountry")}
            value={profile?.bank_country}
            onChange={handleChangeAutoComplete}
            dataattribute="bank_country"
            error={isValidation.bank_country}
            required
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.swift}
            onChange={handleChange("swift")}
            caption={getText("pages.cabinet.swiftNo")}
            data-castil="swift"
            error={isValidation.swift}
            helperMessage="Empty input"
            required
          />
        </Box>

        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.account_number}
            onChange={handleChange("account_number")}
            caption={getText("pages.cabinet.acctNo")}
            data-castil="account_number"
            error={isValidation.account_number}
            helperMessage="Empty input"
            required
          />
        </Box>

        <Box mt={2}>
          <FormLabel className={classes.input} component="legend">
            <Typography className={classes.labelInput}>
              {getText("pages.cabinet.antiMoneyInfo.wiringBank")} /
              <a href="https://www.fatf-gafi.org/countries" target="_blank" rel="noreferrer" className={classes.linkText}>
                FATF Countries
              </a>
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-label="is_wiring_bank_fatf_approved"
            value={profile.is_wiring_bank_fatf_approved || null}
            className={classes.radioGroup}
            onChange={handleChange("is_wiring_bank_fatf_approved")}
          >
            <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" style={{display: 'none'}} />
            <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" style={{display: 'none'}} />
            {
              profile.is_wiring_bank_fatf_approved && profile.is_wiring_bank_fatf_approved.length &&
              (profile.is_wiring_bank_fatf_approved.toLowerCase() === 'true' ||
              profile.is_wiring_bank_fatf_approved.toLowerCase() === 'yes') ? 'Yes' : 'No'
            }
            {
              (!profile.is_wiring_bank_fatf_approved || !profile.is_wiring_bank_fatf_approved.length) &&
              'Please, fill the company country'
            }
          </RadioGroup>
          <Box mt={2}>
            <Typography variant="body2" className={classes.fontSmall}>{getText("pages.cabinet.antiMoneyInfo.yes")}</Typography>
            <Typography variant="body2" className={classes.fontSmall}>{getText("pages.cabinet.antiMoneyInfo.no")}</Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <FormLabel className={classes.input} component="legend">
            <Typography className={classes.labelInput}>{getText("pages.cabinet.antiMoneyInfo.investorCostumer")}</Typography>
          </FormLabel>
          <RadioGroup
            aria-label="is_wiring_bank_customer"
            value={profile.is_wiring_bank_customer || null}
            className={classes.radioGroup}
            onChange={handleChange("is_wiring_bank_customer")}
          >
            <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </Box>

        <Box className={classes.bottomLine} />
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.total_estimated_net_wealth}
            onChange={handleChange("total_estimated_net_wealth")}
            caption={getText("pages.cabinet.estimatedIncluding.totalEstimatedWealth")}
            data-castil="total_estimated_net_wealth"
            error={isValidation.total_estimated_net_wealth}
            helperMessage="Empty input"
            required
          />
        </Box>
        <Box className={classes.bottomLine} />
        <span style={{ fontSize: "20px" }}>Source of Total Wealth </span>
        <p>
          (Please state company name(s). Describe the activities which have generated your total net worth both within and outside
          of the business relationship)
        </p>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.employment_wealth}
            onChange={handleChange("employment_wealth")}
            caption={getText("pages.cabinet.sourceTotalWealth.employment")}
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.dividens_wealth}
            onChange={handleChange("dividens_wealth")}
            caption={getText("pages.cabinet.sourceTotalWealth.dividends")}
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.paternal_inheritance}
            onChange={handleChange("paternal_inheritance")}
            caption={getText("pages.cabinet.sourceTotalWealth.inheritance")}
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.property_sales_wealth}
            onChange={handleChange("property_sales_wealth")}
            caption={getText("pages.cabinet.sourceTotalWealth.propertySales")}
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.investments_sales_wealth}
            onChange={handleChange("investments_sales_wealth")}
            caption={getText("pages.cabinet.sourceTotalWealth.saleOfInvestments")}
          />
        </Box>
        <Box mt={2}>
          <TextInput
            type="text"
            className={classes.input}
            value={profile?.other_sales_wealth}
            onChange={handleChange("other_sales_wealth")}
            caption={getText("pages.cabinet.sourceTotalWealth.other")}
          />
        </Box>
        <Box className={classes.bottomLine} />

        <Box mt={2}>
  
          <Box id="individual_interest_ownership" mt={2}>
            <AutocompleteMaterial
              options={SELECTS_OPTIONS_CABINET.interestOwnershipTypes.map((el) => ({
                label: getText(`${el.label}`),
                value: el.value,
              }))}
              text="individual_interest_ownership"
              title="pages.cabinet.interestOwnershipTypes.title"
              value={profile?.individual_interest_ownership}
              onChange={handleChangeAutoComplete}
            />
            {profile.individual_interest_ownership === "OT" && (
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.individual_interest_ownership_other}
                onChange={handleChange("individual_interest_ownership_other")}
                caption={getText("pages.cabinet.interestOwnershipTypes.titleOther")}
              />
            )}
          </Box>
        </Box>

        <Box className={classes.bottomLine} />

        <Box mt={2}>
          <FormLabel className={classes.input} component="legend">
            <Typography className={classes.labelInput}>
              {getText("pages.cabinet.seniorForeignPolitical")}
              <Hidden xsDown>
                <Tooltip placement="right" title={getText("pages.cabinet.seniorForeignPoliticalTooltip")}>
                  <Box ml={1} mt={-0.2}>
                    <Helper disableRipple>?</Helper>
                  </Box>
                </Tooltip>
              </Hidden>
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-label="is_senior_foreign_political_figure"
            value={profile.is_senior_foreign_political_figure || null}
            className={classes.radioGroup}
            onChange={handleChange("is_senior_foreign_political_figure")}
          >
            <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </Box>
        <Box className={classes.bottomLine} />
        <Box mt={2}>
          <FormLabel className={classes.input} component="legend">
            <Typography className={classes.labelInput}>{getText("pages.cabinet.criminalVictions")} </Typography>
          </FormLabel>
          <RadioGroup
            aria-label="convictions"
            value={profile.convictions || null}
            className={classes.radioGroup}
            onChange={handleChange("convictions")}
          >
            <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </Box>
        {profile.convictions === "YES" && (
          <Box mt={2}>
            <TextInput
              type="text"
              caption=" "
              className={classes.input}
              value={profile?.criminal_descriptions}
              onChange={handleChange("criminal_descriptions")}
              data-castil="criminal_descriptions"
              error={isValidation.criminal_descriptions}
              helperMessage="Empty input"
              required
            />
          </Box>
        )}
        <Box className={classes.bottomLine} />
        <Box mt={2}>
          <FormLabel className={classes.input} component="legend">
            <Typography className={classes.labelInput}>{getText("pages.cabinet.underInvestigation")} </Typography>
          </FormLabel>
          <RadioGroup
            aria-label="under_investigation"
            value={profile.under_investigation || null}
            className={classes.radioGroup}
            onChange={handleChange("under_investigation")}
          >
            <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </Box>
        {profile.under_investigation === "YES" && (
          <Box mt={2}>
            <TextInput
              type="text"
              caption=" "
              className={classes.input}
              value={profile?.under_investigation_text}
              onChange={handleChange("under_investigation_text")}
              data-castil="under_investigation_text"
              error={isValidation.under_investigation_text}
              helperMessage="Empty input"
              required
            />
          </Box>
        )}
        <Box className={classes.bottomLine} />
       
        <Box mt={2}>
          <MultiSelect
            select={SELECTS_OPTIONS_CABINET}
            moreText
            handleChangeSelect={handleChangeSelect}
            profile={profile.accredited_investor_representation_points}
            type="accredited_investor_representation_points"
            dataattribute="accredited_investor_representation_points"
            error={isValidation.accredited_investor_representation_points}
            required
          />
        </Box>
        {profile?.accredited_investor_representation_points &&
          profile?.accredited_investor_representation_points.includes("OTHER_ENTITIES") && (
            <Box mt={2}>
              <MultiSelect
                select={SELECTS_OPTIONS_CABINET}
                moreText
                handleChangeSelect={handleChangeSelect}
                profile={profile.accredited_investor_representation_points_other}
                type="accredited_investor_representation_points_other"
                dataattribute="accredited_investor_representation_points_other"
                error={isValidation.accredited_investor_representation_points_other}
                required
              />
            </Box>
          )}
        <Box className={classes.bottomLine} />

        <Box mt={2}>
          <style>{`
            .${classes.checkboxTitleSmall} > span {
              font-size: 12px;
            }
          `}</style>
          <FormControlLabel
            className={`${classes.checkboxTitle} ${classes.checkboxTitleSmall}`}
            label={`
              ${getText("pages.cabinet.isRisksApproved.investorQuestionnaire")}\n
              ${getText("pages.cabinet.isRisksApproved.acceptedAltaclub")}\n
              ${getText("pages.cabinet.isRisksApproved.dataProvided")}
            `}
            control={
              <Checkbox
                checked={profile.is_risks_approved}
                onChange={handleChangeChecked("is_risks_approved")}
                color="primary"
                className={!isValidation.is_risks_approved ? classes.checkboxError : classes.checkbox}
                data-castil="is_risks_approved"
                error={!isValidation.is_risks_approved}
              />
            }
          />
        </Box>

        <Box className={classes.bottomLine} />

        <Box mt={2} display="flex" justifyContent="space-between" flexDirection={window.innerWidth < 1024 ? 'column' : 'row'}>
          <Box>
            <canvas width="325" height="75" ref={canvasTextRef} />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.signature_text}
              onChange={handleChange("signature_text")}
              caption="Signature"
              data-castil="signature_text"
              error={isValidation.signature_text}
              required
            />
          </Box>
          <Box mt={2.5}>
            <canvas width="325" height="75" ref={canvasDateRef} />
            <span className={classes.datePickerLabel}>Date *</span>
            <DatePicker
              className={classes.pickerContainerElement}
              onChange={handleChangeDate("profile_signed_date")}
              format="DD/MM/YYYY"
              animateYearScrolling
              value={profile?.profile_signed_date}
              data-castil="profile_signed_date"
              error={isValidation.profile_signed_date}
            />
          </Box>
        </Box>
      </div>
    );
  }
);
