import React, { useState, useCallback, useEffect } from "react";
import { Box, Typography, Button, IconButton, Grid } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import * as numeral from "numeraljs";
import { NavLink } from "react-router-dom";
import useLanguage from "../../languages/index";
import Empty from "../../components/Empty";
import PopupPaper from "../../components/PopupPaper";
import SecondaryKnowMorePopup from "../../components/SecondaryKnowMorePopup";
import SecondaryCancellationPopup from "../../components/SecondaryCancellationPopup";
import SecondarySuccessPopup from "../../components/SecondarySuccessPopup";
import { useSecondaryMarket, useSecondaryApplications, useSecondaryMyApplications } from "../../api/api";
import { HeaderCell, useStylesSecondary } from "./styled";
import { useStore } from "../../store";

const getColumns = () => {
  const isBuy = window.location.href.includes('buy')

  if (isBuy) {
    return [
      { width: "25%", labelId: "pages.secondary.table.name" },
      { width: "25%", labelId: "pages.secondary.table.sharesAmount" },
      { width: "25%", labelId: "pages.secondary.table.avgPps" },
      { width: "25%", labelId: "pages.secondary.table.operation" },
    ]
  }

  const isApplications = window.location.href.includes('applications')

  if (isApplications) {
    return [
      { width: "25%", labelId: "pages.secondary.table.name" },
      { width: "25%", labelId: "pages.secondary.table.sharesAmount" },
      { width: "25%", labelId: "pages.secondary.table.avgPps" },
      { width: "25%", labelId: "pages.secondary.table.operation" },
    ]
  }

  return [
    { width: "20%", labelId: "pages.secondary.table.name" },
    { width: "18%", labelId: "pages.secondary.table.sharesAmount" },
    { width: "20%", labelId: "pages.secondary.table.totalInvestedSum" },
    { width: "20%", labelId: "pages.secondary.table.avgPps" },
    { width: "22%", labelId: "pages.secondary.table.operation" },
  ]
}

const getSecondaryMethod = () => {
  const isBuy = window.location.href.includes('buy')

  if (isBuy) {
    return useSecondaryApplications()
  }

  const isApplications = window.location.href.includes('applications')

  if (isApplications) {
    return useSecondaryMyApplications()
  }

  return useSecondaryMarket()
}

const ModalText = ({ text, href }) => {
  const classes = useStylesSecondary();

  return (
    <div className={classes.ModalMessage}>
      <p className={classes.text}>{text}</p>
      {href && (
        <NavLink component={Button} to={href} className={classes.buttonModal} size="large" color="primary" variant="contained">
          Ok
        </NavLink>
      )}
    </div>
  );
};

export default function Secondary({ closed, purchase, isApplications }) {
  const getText = useLanguage();
  const classes = useStylesSecondary();
  const [secondaryDeposit, fetchSecondaryDeposit] = getSecondaryMethod();
  const [modalStartup, setModalStartup] = useState();
  const [startUpList, setStartUpList] = useState();
  const [openInvest, openInvestDialog] = useState(false);
  const [openCancellation, openCancellationDialog] = useState(false);
  const [openSuccess, openSuccessDialog] = useState(false);
  const [openedApplications, setOpenedApplications] = useState([]);
  const store = useStore();

  useEffect(() => {
    fetchSecondaryDeposit();
  }, [purchase]);

  const columns = getColumns();

  const prepareStartupList = (data) => {
    const startupList = []

    for (const instance in data) {
      if (data[instance]) {
        const instanceData = data[instance]

        for (const startup in instanceData) {
          if (instanceData[startup]) {
            const startupData = instanceData[startup]

            // console.log('startup', startupData)

            if (Array.isArray(startupData)) {
              for (const application of startupData) {
                const startupObj = application.startup || application

                startupList.push({
                  instance,
                  name: startup,
                  shares: application.shares_amount,
                  estimation: application.estimation,
                  pps: application.pps,
                  logo: application.startup_logo || startupObj.logo,
                  teaser: application.startup_teaser || startupObj.teaser,
                  site: startupObj.site,
                  id: startupObj.id,
                  minDealAmount: application.min_deal_amount,
                  maxDealAmount: application.max_deal_amount,
                  applicationId: application.application_id
                })
              }
            } else {
              const startupObj = startupData.startup || startupData

              startupList.push({
                instance,
                name: startup,
                shares: startupData.shares_amount,
                estimation: startupData.estimation,
                pps: startupData.pps,
                logo: startupData.startup_logo || startupObj.logo,
                teaser: startupData.startup_teaser || startupObj.teaser,
                site: startupObj.site,
                id: startupObj.id,
                minDealAmount: startupData.min_deal_amount,
                maxDealAmount: startupData.max_deal_amount,
              })
            }
          }
        }
      }
    }

    return startupList
  }

  useEffect(() => {
    if (secondaryDeposit.complete && !secondaryDeposit.error) {
      setStartUpList(
        prepareStartupList(
          secondaryDeposit.data
        )
      );
    }
  }, [secondaryDeposit]);
 
  const handleInvest = (hasAction = false) => {
    openInvestDialog(false);

    if (hasAction) {
      openSuccessDialog(true);
    }
  };

  const handleCancellation = (hasAction = false) => {
    fetchSecondaryDeposit();
    openCancellationDialog(false);

    if (hasAction) {
      openSuccessDialog(true);
    }
  };

  const handleOpen = useCallback((startup, isCancellation = false) => {
    setModalStartup(startup);

    if (!isCancellation) {
      openInvestDialog(true);
    } else {
      openCancellationDialog(true);
    }
  });

  const groupedByName = (array) => array.reduce((acc, obj) => {
    const key = obj.name;
    if (!acc[key]) {
        acc[key] = [];
    }
    acc[key].push(obj);

    return acc;
  }, {});

  const openApplication = (startupName) => {
    if (!openedApplications.includes(startupName)) {
      setOpenedApplications([...openedApplications, startupName])
    } else {
      setOpenedApplications([...openedApplications.filter((name) => name !== startupName)])
    }
  };

  const modalWindow = () => {
    let text = "";
    let href = "";

    if (!store.profileUS.is_kyc_checked) {
      text = getText("pages.investments.modalText.kyc_signed");
      href = "/settings/profile";
    }

    if (store.profileUS.is_kyc_checked && !store.profileUS.is_accredited) {
      text = getText("pages.investments.modalText.accredited");
    }

    if (store.profileUS.is_kyc_checked && store.profileUS.is_accredited) {
      text = getText("pages.investments.modalText.accredited");
    }

    if (store.profileUS.is_kyc_checked && store.profileUS.is_accredited) {
      text = getText("pages.investments.modalText.moderate");
    }

    return <ModalText text={text} href={href} />;
  };

  return (
    <>
      <SecondaryKnowMorePopup
        onClose={handleInvest}
        title={getText("pages.secondary.saleStocks")}
        open={openInvest}
        modalStartup={modalStartup}
        purchase={purchase}
      />
      <SecondaryCancellationPopup
        onClose={handleCancellation}
        title={getText("pages.secondary.saleStocks")}
        open={openCancellation}
        modalStartup={modalStartup}
      />
      <SecondarySuccessPopup
        onClose={() => openSuccessDialog(false)}
        title="Successfully"
        open={openSuccess}
        cancellation={isApplications}
      />

      {store.profileUS.is_member && store.profileUS.is_kyc_checked && store.profileUS.is_accredited ?
        (
          <Box className={classes.container}>
            <Box className={classes.headerTitle}>
              {columns
                .map((column) => (
                  <HeaderCell width={column.width} style={{ margin: 0 }}>
                    {getText(column.labelId)}
                  </HeaderCell>
                ))
              }
            </Box>
            <Box mt={1.5}>
              {!purchase && !isApplications && startUpList && (
                startUpList.map((startUp) => (
                  <PopupPaper className={classes.paper}>
                    <Box width={columns[0].width} pr={3}>
                      <a href={startUp.site} target="_blank" rel="noreferrer noopener">
                        <img src={startUp.logo} alt={startUp.name} style={{maxWidth: '100px', maxHeight: '50px'}} />
                      </a>
                      <Typography variant="body2" style={{ visibility: 'hidden', display: 'none' }}>{startUp.name}</Typography>
                    </Box>
                    <Box width={columns[1].width} pr={3}>
                      <Typography variant="body2">{numeral(startUp.shares).format("0,0")}</Typography>
                    </Box>
                    {!purchase && !isApplications &&
                      <>
                        <Box width={columns[2].width} pr={3}>
                          <Typography variant="body2">{numeral(startUp.estimation).format("$0,0.00")}</Typography>
                        </Box>
                        <Box width={columns[3].width} pr={3}>
                          <Typography variant="body2">{numeral(startUp.pps).format("$0,0.00")}</Typography>
                        </Box>
                      </>
                    }
                    {(purchase || isApplications) &&
                      <>
                        <Box width={columns[2].width} pr={3}>
                          <Typography variant="body2">{numeral(startUp.minDealAmount).format("$0,0.00")}</Typography>
                        </Box>
                      </>
                    }
                    {!isApplications && 
                      <Box width={columns[3].width} pr={3} display="flex" flexDirection="column">
                        <Button
                          className={classes.moreButton}
                          onClick={() => handleOpen(startUp)}
                          variant="contained"
                          color="secondary"
                        >
                          <b>{purchase ? getText("pages.secondary.buy") : getText("pages.secondary.sale")}</b>
                        </Button>
                      </Box>
                    }
                    {isApplications && 
                      <Box width={columns[3].width} pr={3} display="flex" flexDirection="column">
                        <Button
                          className={classes.moreButton}
                          onClick={() => handleOpen(startUp, true)}
                          variant="contained"
                          color="secondary"
                        >
                          <b>{getText("pages.secondary.cancel")}</b>
                        </Button>
                      </Box>
                    }
                  </PopupPaper>
                ))
              )}
    
              {(purchase || isApplications) && startUpList && (
                Object.keys(groupedByName(startUpList)).map((key) => (
                  <>
                    <PopupPaper className={classes.paper}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => openApplication(key)}
                      >
                        {openedApplications.includes(key) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                      <Box width='20%'>
                        <a
                          href={groupedByName(startUpList)[key][0].site}
                          target="_blank" rel="noreferrer noopener"
                        >
                          <img
                            src={groupedByName(startUpList)[key][0].logo}
                            alt={groupedByName(startUpList)[key][0].name}
                            style={{maxWidth: '100px', maxHeight: '50px'}}
                          />
                        </a>
                      </Box>
                      <Box width='28%' display="flex" flexDirection="column" />
                      <Box width='25%' display="flex" flexDirection="column" />
                      <Box width='23.5%' display="flex" flexDirection="column">
                        {groupedByName(startUpList)[key][0].teaser &&
                          <Button
                            className={classes.moreButton}
                            component="a"
                            href={groupedByName(startUpList)[key][0].teaser}
                            target="_blank"
                            variant="contained"
                            color="secondary"
                          >
                            <b>{getText("pages.secondary.teaser")}</b>
                          </Button>
                        }
                      </Box>
                    </PopupPaper>
                    {openedApplications.includes(key) &&
                      groupedByName(startUpList)[key].map((startUp) => (
                        <PopupPaper className={classes.paper}>
                          <Box width={columns[0].width} pr={3}>
                            <a href={startUp.site} target="_blank" rel="noreferrer noopener">
                              <img src={startUp.logo} alt={startUp.name} style={{maxWidth: '100px', maxHeight: '50px'}} />
                            </a>
                            <Typography variant="body2" style={{ visibility: 'hidden', display: 'none' }}>{startUp.name}</Typography>
                          </Box>
                          <Box width={columns[1].width} pr={3}>
                            <Typography variant="body2">{numeral(startUp.shares).format("0,0")}</Typography>
                          </Box>
                          <Box width={columns[2].width} pr={3}>
                            <Typography variant="body2">{numeral(startUp.minDealAmount).format("$0,0.00")}</Typography>
                          </Box>
                          {!isApplications && 
                            <Box width={columns[3].width} pr={3} display="flex" flexDirection="column">
                              <Button
                                className={classes.moreButton}
                                onClick={() => handleOpen(startUp)}
                                variant="contained"
                                color="secondary"
                              >
                                <b>{purchase ? getText("pages.secondary.buy") : getText("pages.secondary.sale")}</b>
                              </Button>
                            </Box>
                          }
                          {isApplications && 
                            <Box width={columns[3].width} pr={3} display="flex" flexDirection="column">
                              <Button
                                className={classes.moreButton}
                                onClick={() => handleOpen(startUp, true)}
                                variant="contained"
                                color="secondary"
                              >
                                <b>{getText("pages.secondary.cancel")}</b>
                              </Button>
                            </Box>
                          }
                        </PopupPaper>
                    ))}
                  </>
                ))
              )}
              
              {!startUpList &&
                <Box className={classes.empty}>
                  <Empty text={getText("pages.secondary.emptyMessage")} />
                </Box>
              }
            </Box>
          </Box>  
        )
      :
        (
          <Grid container spacing={3} className={classes.gridContainer}>
            {modalWindow()}
          </Grid>
        )
      }
    </>
  );
}
