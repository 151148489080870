import React, { useState } from "react";
import { Dialog, DialogTitle, Button, TextField, DialogContent, DialogActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useLanguage from "../languages/index";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "39px 110px 56px",
  },
  dialogTitle: {
    padding: "16px 0",
    textAlign: "center",
    "& h2": {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  redButton: {
    background: theme.palette.text.red,
    color: theme.palette.background.default,
    "&:hover": {
      background: theme.palette.text.red,
    },
  },
  greenButton: {
    background: theme.palette.text.green,
    color: theme.palette.background.default,
    "&:hover": {
      background: theme.palette.text.green,
    },
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 24,
  },
}));

export default function DeleteDialog({ title, open, onClose, id, send }) {
  const classes = useStyles();
  const getText = useLanguage();
  const [textValue, setTextValue] = useState("");

  const confirm = () => {
    onClose(true, id, textValue);
  };
  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth open={open}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      {send && (
        <DialogContent style={{ padding: 8 }}>
          <TextField
            autoFocus
            id="invest"
            type={send ? "number" : "text"}
            value={textValue}
            onChange={(event) => setTextValue(event.target.value)}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
        </DialogContent>
      )}
      <div className={classes.buttons}>
        <Button onClick={confirm} className={send ? classes.greenButton : classes.redButton} size="large" variant="contained">
          {send ? getText("send") : getText("delete")}
        </Button>
        <Button onClick={() => onClose(false, id, textValue)} size="large" variant="contained" color="secondary">
          {getText("cancel")}
        </Button>
      </div>
    </Dialog>
  );
}
