import React, { useCallback, useLayoutEffect } from "react";
import { Button, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation/Navigation";
import { useStore } from "../store";
import useLanguage, { langMap } from "../languages";
import PopupPaper from "../components/PopupPaper";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      background: "#FFFFFF",
      display: "block",
    },
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
  },
  button: { margin: "25px 0" },
}));

export default observer(({ location }) => {
  const classes = useStyles();
  const store = useStore();
  const getText = useLanguage();

  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);
    const languageParam = params.get("language");
    langMap.forEach((el) => el.code === languageParam && store.setLang(languageParam));
  }, [location.search]);

  return (
    <Container className={classes.root}>
      <Navigation key="navigation" login location={location} />
      <PopupPaper popup caption={getText("pages.login.greeting")}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" mt={2}>
          <Link to="/login">
            <Button type="submit" fullWidth size="large" variant="contained" color="primary" className={classes.button}>
              {getText("pages.login.caption")}
            </Button>
          </Link>
          <a
            href="https://us.altaclub.vc/register"
            className="nav-link d-inline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="submit" fullWidth size="large" variant="contained" color="primary" className={classes.button}>
              {getText("pages.signUp.registerNow")}
            </Button>
          </a>
        </Box>
      </PopupPaper>
    </Container>
  );
});
