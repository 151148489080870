export const createStore = () => ({
  language: localStorage.getItem("language") || "en",
  setLang(code) {
    this.language = code;
    localStorage.setItem("language", code);
  },
  loading: false,
  setLoading(state) {
    this.loading = state;
  },
  error: false,
  setError(state) {
    this.error = state;
  },
  profile: {},
  setProfile(data) {
    this.profile = data;
  },
  profileUS: {},
  setProfileUS(data) {
    this.profileUS = data;
  },
  ubo: [],
  setProfileUBO(data) {
    this.ubo = data;
  },
  shareholder: [],
  setProfileShareholder(data) {
    this.shareholder = data;
  },
  director: [],
  setProfileDirector(data) {
    this.director = data;
  },
  rounds: [],
  setRounds(data) {
    this.rounds = data;
  },
  startup: [],
  setStartup(data) {
    this.startup = data;
  },
  news: [],
  setNews(data) {
    this.news = data;
  },
  moreNews: [],
  setMoreNews(data) {
    this.moreNews = data;
  },
  reportNews: [],
  setReportNews(data) {
    this.reportNews = data;
  },
  investor: [],
  setInvestor(data) {
    this.investor = data;
  },
  pdf: [],
  setPDF(data) {
    this.pdf = data;
  },
  token: {
    access: localStorage.getItem("access", ""),
    refresh: localStorage.getItem("refresh", ""),
  },
  clearToken() {
    this.token = { access: null, refresh: null };
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  },
  setToken(token, remember) {
    this.token = {
      access: token.access || null,
      refresh: token.refresh || this.token.refresh || null,
    };

    if (remember) {
      localStorage.setItem("access", this.token.access);
      localStorage.setItem("refresh", this.token.refresh);
    }
  },
  notificationsSummary: 0,
  setNotificationsSummary(data) {
    this.notificationsSummary = data;
  },

  alertOnDeals: false,
  alertOnNewRounds: false,
  alertOnOther: false,
  setAlertOnDeals(data) {
    this.alertOnDeals = data;
  },
  setAlertOnNewRounds(data) {
    this.alertOnNewRounds = data;
  },
  setAlertOnOther(data) {
    this.alertOnOther = data;
  },

  selectOption: "",
  setSelectOption(data) {
    this.selectOption = data;
    if (data === "") {
      this.valueSearchText = "";
      this.valueSearchType = "";
      this.valueSearchDateStart = Date.now();
      this.valueSearchDateFinish = Date.now();
      this.queryDate = "";
      this.valueSearchStartup = "";
      this.valueSearchContract = "";
      this.valueSearchAttachment = "";
    }
  },
  valueSearchText: "",
  setValueSearchText(data) {
    this.valueSearchText = data;
  },
  valueSearchType: "",
  setValueSearchType(data) {
    this.valueSearchType = data;
  },
  valueSearchDateStart: Date.now(),
  setValueSearchDateStart(data) {
    this.valueSearchDateStart = data;
  },
  valueSearchDateFinish: Date.now(),
  setValueSearchDateFinish(data) {
    this.valueSearchDateFinish = data;
  },
  queryDate: "",
  setQueryDate(date) {
    this.queryDate = date;
  },
  valueSearchStartup: "",
  setValueSearchStartup(data) {
    this.valueSearchStartup = data;
  },
  valueSearchContract: "",
  setValueSearchContract(data) {
    this.valueSearchContract = data;
  },
  valueSearchAttachment: "",
  setValueSearchAttachment(data) {
    this.valueSearchAttachment = data;
  },
  valueLocation: "",
  setValueLocation(data) {
    this.valueLocation = data;
  },
  openModalNotifications: false,
  setOpenModalNotifications(data) {
    this.openModalNotifications = data;
  },
  orderBy: 1,
  setOrderBy(data) {
    this.orderBy = data;
  },
  sortDirection: "desc",
  setSortDirection(data) {
    this.sortDirection = data;
  },
  unread: 0,
  setUnread(data) {
    this.unread = data;
  },
  documents: [],
  setDocuments(data) {
    this.documents = data;
  },
  primaryRegisterData: {},
  setPrimaryRegisterData(data) {
    this.primaryRegisterData = data;
  },
  secondaryRegisterData: {},
  setSecondaryRegisterData(data) {
    this.secondaryRegisterData = data;
  },
  citizenships_en: [
    "AF",
    "AX",
    "AL",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AC",
    "AU",
    "AT",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BY",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BA",
    "BW",
    "BR",
    "IO",
    "VG",
    "BN",
    "BG",
    "BF",
    "BI",
    "KH",
    "CM",
    "CA",
    "IC",
    "CV",
    "BQ",
    "KY",
    "CF",
    "EA",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CG",
    "CD",
    "CK",
    "CR",
    "CI",
    "HR",
    "CU",
    "CW",
    "CY",
    "CZ",
    "DK",
    "DG",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "EE",
    "ET",
    "EZ",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GW",
    "GY",
    "HT",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "XK",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "LT",
    "LU",
    "MO",
    "MK",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "MX",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "NL",
    "NC",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "KP",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RU",
    "RW",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SX",
    "SK",
    "SI",
    "SB",
    "SO",
    "ZA",
    "GS",
    "KR",
    "SS",
    "ES",
    "LK",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "SD",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "SY",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TA",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "UM",
    "VI",
    "UG",
    "UA",
    "AE",
    "GB",
    "UN",
    "US",
    "UY",
    "UZ",
    "VU",
    "VA",
    "VE",
    "VN",
    "WF",
    "EH",
    "YE",
    "ZM",
    "ZW",
  ],
});
