import React from "react";
import { withStyles, makeStyles } from "@material-ui/styles";
import emptyIcon from "../assets/emptyIcon.svg";

const EmptyIcon = withStyles((_theme) => ({
  root: {
    backgroundImage: `url(${emptyIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 72,
    minHeight: 100,
  },
}))(({ classes }) => <div className={classes.root} />);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  message: {
    marginTop: 20,
    maxWidth: 300,
    textAlign: "center",
  },
}));

export default function ({ style, text, noIcon = false }) {
  const classes = useStyles();
  return (
    <div style={style} className={classes.root}>
      {!noIcon && <EmptyIcon />}
      <div className={classes.message}>{text}</div>
    </div>
  );
}
