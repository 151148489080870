import { createTheme } from "@material-ui/core/styles";

const defaultFontFamily = "Open Sans";

const defaultTheme = createTheme();
const theme = createTheme({
  typography: {
    fontFamily: `"${defaultFontFamily}", ${defaultTheme.typography.fontFamily}`,
    body2: {
      // overflowY: 'scroll',
      [defaultTheme.breakpoints.down("xs")]: {
        // background: '#FFFFFF'
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  overrides: {
    MuiPaper: {
      elevation8: {
        border: "1px solid #DBE5EA",
        boxShadow: "0px 8px 24px rgba(57, 110, 190, 0.08)",
      },
      elevation1: {
        boxShadow: "0px 8px 24px rgba(57, 110, 190, 0.08)",
      },
      root: {
        [defaultTheme.breakpoints.down("xs")]: {
          boxShadow: "none !important",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#FFF",
        color: defaultTheme.palette.text.primary,
        border: "1px solid",
        borderColor: "rgba(126, 159, 193, .5)",
        whiteSpace: "pre-line",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F1F3F4",
          "&:hover": {
            backgroundColor: "#F1F3F4",
          },
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "#E4EEFA",
          color: "#374464",
        },
      },
    },
    MuiDivider: {
      root: {
        [defaultTheme.breakpoints.down("xs")]: {
          marginLeft: -20,
          marginRight: -20,
        },
        backgroundColor: "#DBE5EA",
      },
    },
    MuiContainer: {
      root: { display: "flex" },
    },
    MuiTab: {
      root: { fontSize: "0.875rem !important", minHeight: "auto" },
    },
    MuiInputBase: {
      multiline: {
        padding: 0,
      },
      input: {
        borderRadius: "6px !important",
        position: "relative",
        background: "#f1f3f4",
        height: 48,
        boxSizing: "border-box",
        padding: "12px 21px",
        paddingRight: 48,
        "&.MuiInputBase-inputAdornedStart": {
          paddingLeft: 48,
        },
      },
      inputMultiline: {
        padding: "12px 21px",
        overflow: "auto !important",
        lineHeight: "1.5rem",
      },
      root: {
        marginTop: 5,
        borderRadius: 6,
        "&$error": {
          boxShadow: "0px 0px 1px 1px #F36363",
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgb(228 238 250 / .9)",
        left: "auto !important",
        right: "auto !important",
        width: "100%",
        height: "100%",
      },
    },
    MuiDialog: {
      root: {
        left: "260px !important",
        [defaultTheme.breakpoints.down("md")]: {
          left: "0 !important",
        }
      },
      paper: {
        [defaultTheme.breakpoints.down("xs")]: {
          margin: 12,
          width: "100% !important",
          padding: "39px 27px 56px !important",
        },
        [defaultTheme.breakpoints.down("md")]: {
          padding: "0 !important",
        }
      },
    },
    MuiTableCell: {
      root: {
        overflowY: "auto",
        borderBottomColor: "#E4EEFA",
        padding: "14px 10px 14px 16px",
        "&:first-child": { paddingLeft: 0 },
        "&:last-child": { paddingRight: 0 },
        color: "#7E9FC1",
      },
      head: {
        color: "#7E9FC1",
      },
    },
    MuiExpansionPanel: {
      root: {
        borderRadius: "10px !important",
        margin: "16px 0 !important",
        [defaultTheme.breakpoints.down("xs")]: {
          marginLeft: "-20px !important",
          marginRight: "-20px !important",
          "&:before": {
            opacity: "0 !important",
          },
          "&$expanded": {
            borderRadius: "0 !important",
            borderBottom: "1px solid #DBE5EA",
          },
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        overflow: "auto",
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        [defaultTheme.breakpoints.down("xs")]: {
          borderRadius: 0,
        },
        borderRadius: 10,
        minHeight: "82px !important",
        "&$expanded": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        "&$focused": {
          backgroundColor: "#fff",
        },
      },
      content: {
        margin: "20px 0",
      },
      expandIcon: {
        alignSelf: "baseline",
        marginTop: 8,
      },
    },
    MuiSvgIcon: {
      colorSecondary: {
        fill: "#7E9FC1",
      },
    },
    MuiButton: {
      root: {
        boxShadow: "none !important",
        border: "none",
        fontSize: "0.875rem",
        padding: "4px 16px",
        textTransform: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      contained: {
        backgroundColor: "#FFFFFF",
        color: "#374464",
      },
      containedSecondary: {
        color: "#7E9FC1",
        "&:hover": {
          backgroundColor: "#e1efff",
        },
      },
      sizeLarge: {
        padding: "12px 46px",
        fontSize: "0.875rem",
      },
    },
    MuiSelect: {
      selectMenu: {
        display: "flex",
        alignItems: "center",
      },
    },
    MuiToolbar: {
      gutters: {
        [defaultTheme.breakpoints.down("xs")]: {
          background: "#fff",
          padding: "0 20px",
        },
      },
      regular: {
        height: "87px",
        [defaultTheme.breakpoints.down("sm")]: {
          height: "60px",
        }
      },
    },
  },
  palette: {
    background: {
      default: "#F5F6FA",
      input: "#F1F3F4",
    },
    text: {
      primary: "#374464",
      secondary: "#7E9FC1",
      green: "#4DCC97",
      red: "#F36363",
    },
    primary: {
      main: "#2356B2",
    },
    secondary: {
      main: "#EBF2FF",
      light: "#e1efff",
    },
  },
  autoComplete: {
    "&.Mui-focused": {
      boxShadow: "none",
    },
    "& > div": {
      "& > label": {
        fontSize: 18,
        boxShadow: "none",
        position: "static",
        marginTop: 4,
        marginBottom: -10,
      },
      "& > div ": {
        background: "#f1f3f4",
      },
    },
    [defaultTheme.breakpoints.down("sm")]: {
      left: 'unset !important'
    }
  },
  MuiautoCompleteTextfiled: {
    "& > div": {
      padding: "5px 21px",
    },
  },
});

export default theme;
