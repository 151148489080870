import React, { useState } from "react";
import { AppBar, Toolbar, Tabs, Tab, Link, Hidden, IconButton, Drawer, Box, Typography, Divider } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import VideoLibraryOutlinedIcon from "@material-ui/icons/VideoLibraryOutlined";
import { observer } from "mobx-react-lite";
import urls, { useAsyncEndpoint } from "../../urls";
import logo from "../../assets/altaclub_logo.svg";
import { useStore } from "../../store";
import useLanguage, { langMap } from "../../languages";
import { InvestIcon, BriefIcon, ArrowIcon, AssignmentIcon, NewsIcon, SecondaryMarketIcon } from "../Icons";
import SexyLink from "../SexyLink";
import { SidebarTab, useStylesNavigation } from "./styled";
import { mappingLogin } from "../../api/api";

const useProfile = () =>
  useAsyncEndpoint((data) => ({
    url: urls.profile(),
    method: "PUT",
    data,
  }));

export default observer(({ login, history, location }) => {
  const classes = useStylesNavigation();
  const store = useStore();
  const getText = useLanguage();
  const [profileResponse, postProfile] = useProfile();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleChange = (event, value) => {
    const token = store.token.access;
    token && postProfile({ interface_language: value });
    store.setLang(value);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    store.clearToken();
  };

  const onTabChange = (event, value) => {
    if (location.pathname === "/notifications") {
      store.setValueLocation(value);
      store.unread !== 0 ? store.setOpenModalNotifications(true) : history.push(`/${value}`);
    } else {
      history.push(`/${value}`);
    }
  };

  const userHasOneInstance = () => {
    const { has_bo_profile, has_us_profile, has_ae_profile } = store.profile;

    const instanceArray = [has_bo_profile, has_us_profile, has_ae_profile];

    const hasOneInstance = instanceArray.filter((instance) => instance).length === 1;

    return hasOneInstance;
  };

  const getInstanceSubdomain = () => {
    const subdomain = window.location.host.split(".")[0];

    const isSandbox = subdomain === "sandbox";
    const isLocalhost = subdomain.includes("localhost");

    if (isSandbox || isLocalhost) {
      if (store.profile.has_bo_profile) {
        return "bo";
      }

      if (store.profile.has_us_profile) {
        return "us";
      }

      if (store.profile.has_ae_profile) {
        return "ae";
      }
    }

    return subdomain;
  };

  const changeInstance = async (event, instance) => {
    event.preventDefault();

    const response = await mappingLogin(instance, JSON.stringify({ secret: localStorage.access }));

    const responseJson = await response.json();

    const link = document.createElement("a");

    link.href = `https://${instance}.altaclub.vc/login/instance?secret=${responseJson.secret}`;
    document.body.append(link);

    link.click();
    link.remove();
  };

  const drawer = (
    <>
      <Tabs
        orientation="vertical"
        value={location.pathname.split("/")[1]}
        textColor="primary"
        indicatorColor="primary"
        TabIndicatorProps={{
          style: {
            left: 0,
          },
        }}
        onChange={onTabChange}
      >
        <SidebarTab
          value="portfolio"
          onClick={handleDrawerToggle}
          icon={<BriefIcon className={classes.tabIcon} />}
          label={getText("sections.portfolio")}
        />
        <SidebarTab
          value="investments"
          onClick={handleDrawerToggle}
          icon={<InvestIcon className={classes.tabIcon} />}
          label={getText("sections.investments")}
        />
        <SidebarTab
          value="secondary"
          onClick={handleDrawerToggle}
          icon={<SecondaryMarketIcon className={classes.tabIcon} />}
          label={getText("sections.secondary")}
        />
        <SidebarTab
          value="ventureTheory"
          onClick={handleDrawerToggle}
          icon={<VideoLibraryOutlinedIcon className={classes.tabIcon} style={{ fill: "#ccd3d8" }} />}
          label={getText("sections.videoLibrary")}
        />
        {store.profile.is_cyborg && (
          <SidebarTab
            value="report"
            onClick={handleDrawerToggle}
            icon={<AssignmentIcon className={classes.tabIcon} />}
            label={getText("sections.report")}
          />
        )}
        {/* <SidebarTab
                    value="notifications"
                    onClick={handleDrawerToggle}
                    icon={<NotificationCounter classes={classes}/>}
                    label={getText('sections.notifications')}
                /> */}
      </Tabs>
      <Box mt="auto">
        <Divider />
        <Link onClick={handleDrawerToggle} underline="none" component={RouterLink} to="/settings/profile">
          <Box pt={3} pb={3} pl={4} display="flex" alignItems="center">
            <Box>
              <Typography color="primary" variant="subtitle2" gutterBottom>
                {store.profile.investor_legal_type === 1 ? getText("natural") : ""}
                {store.profile.investor_legal_type === 2 ? getText("corporation") : ""}
                {store.profile.investor_legal_type === 3 ? getText("individual") : ""}
              </Typography>
              <Box fontSize={18} fontWeight={600}>
                <Typography color="primary" variant="inherit">
                  {store.profile.investor_legal_type !== 2 ? (
                    <>
                      {store.profile.first_name_en}
                      &nbsp;
                      {store.profile.last_name_en}
                    </>
                  ) : (
                    <>{store.profile.organization_name_en}</>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box ml="auto" mr={1} mt={1.9}>
              <ArrowIcon className={classes.arrowIcon} />
            </Box>
          </Box>
        </Link>
        <Divider />
        {/* <Box mt={3} mb={3} pl={4} display="flex" flexDirection="column">
          {!userHasOneInstance() && (
            <Box mb={2.5}>
              <Tabs className={classes.barLang} value={getInstanceSubdomain()} indicatorColor="primary" textColor="primary">
                {store.profile.has_bo_profile && (
                  <Tab
                    value="bo"
                    label="BVI"
                    disableRipple
                    className={classes.topBarLangButton}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://bo.altaclub.vc/login/instance"
                    onClick={(event) => changeInstance(event, "bo")}
                  />
                )}
                {store.profile.has_us_profile && (
                  <Tab
                    value="us"
                    label="USA"
                    disableRipple
                    className={classes.topBarLangButton}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://us.altaclub.vc/login/instance"
                    onClick={(event) => changeInstance(event, "us")}
                  />
                )}
                {store.profile.has_ae_profile && (
                  <Tab
                    value="ae"
                    label="UAE"
                    disableRipple
                    className={classes.topBarLangButton}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ae.altaclub.vc/login/instance"
                    onClick={(event) => changeInstance(event, "ae")}
                  />
                )}
              </Tabs>
            </Box>
          )}

          <Box fontWeight={400} fontSize={14} mb={1.5} onClick={logout}>
            <SexyLink component={RouterLink} to="/settings/profile">
              {getText("logout")}
            </SexyLink>
          </Box>

          <Tabs
            className={classes.barLang}
            value={store.language}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {langMap.map((lang) => (
              <Tab key={lang.code} value={lang.code} disableRipple className={classes.topBarLangButton} label={lang.label} />
            ))}
          </Tabs>
        </Box> */}
      </Box>
    </>
  );

  return (
    <AppBar className={classes.topBar} color="inherit">
      {login ? (
        <Toolbar>
          <Link component={RouterLink} to="/login" className={classes.logoCenter}>
            <img alt="Alta Club" className={classes.logo} src={logo} />
          </Link>
          <Tabs
            className={classes.topBarLang}
            value={store.language}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {langMap.map((lang) => (
              <Tab key={lang.code} value={lang.code} disableRipple className={classes.topBarLangButton} label={lang.label} />
            ))}
          </Tabs>
        </Toolbar>
      ) : (
        <>
          <Hidden smDown>
            {/* Desktop */}
            <Drawer
              anchor="left"
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
                paperAnchorDockedLeft: classes.drawerLeft,
              }}
              open
            >
              <Link component={RouterLink} to="/">
                <Box height={60} display="flex" width="100%" justifyContent="center" mt={1.5} mb={1.75}>
                  <img alt="Alta Club" height={55} className={classes.logo} src={logo} />
                </Box>
              </Link>
              <Divider />
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden mdUp>
            {/* Mobile */}
            <Toolbar className={classes.toolbar}>
            <Box display="flex" width="100%" height={45} mt={0} mb={.75}>
                <Link component={RouterLink} to="/">
                  <img alt="Alta Club" height={45} className={classes.logo} src={logo} />
                </Link>
                <IconButton
                  disableRipple
                  color="primary"
                  aria-label="open drawer"
                  className={classes.menuButton}
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>
              </Box>
              <Drawer
                variant="temporary"
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
            </Toolbar>
          </Hidden>
        </>
      )}
    </AppBar>
  );
});
