import React, { useState, useEffect } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { observer } from "mobx-react-lite";
import { NotificationManager } from "react-notifications";
import { toJS } from "mobx";
import { makeStyles } from "@material-ui/styles";
import { useStore } from "../../../../store";
import urls, { useAsyncEndpoint } from "../../../../urls";
import PopupPaper from "../../../../components/PopupPaper";
import useLanguage from "../../../../languages";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "39px 110px 56px",
  },
  dialogTitle: {
    padding: "16px 0",
    textAlign: "center",
    "& h2": {
      fontSize: 24,
      fontWeight: 600,
    },
  },
}));

const useSumsubToken = () =>
  useAsyncEndpoint((data) => ({
    url: urls.sumsubTokenUS(),
    method: "GET",
    useUS: true
  }));

export default observer(({ history, location }) => {
  const store = useStore();
  const classes = useStyles();
  const getText = useLanguage();
  const [profile, setProfile] = useState({ ...store.profileUS });
  const [sumsubTokenResponse, fetchSumsubToken] = useSumsubToken();

  useEffect(() => {
    async function fetchGet() {
      let response = await fetch(`https://us.altaclub.vc/api/v1/investors/profile/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("us_access")}`,
        },
      });
      response = await response.json();
      if (response.entity_type === "Individual" && response.citizenship === "") {
        NotificationManager.error(getText("messages.sumsubCitizenship"));
        history.push("/settings/profile");
      }
      if (response.entity_type === "Legal" && !response.is_sumsub_ready) {
        NotificationManager.error(getText("messages.sumsubLegal"));
        history.push("/settings/profile");
      } else {
        fetchSumsubToken();
      }
    }
    fetchGet();
  }, []);

  useEffect(() => {
    const data = toJS(store.profile);
    setProfile(data);
  }, [store.profile]);

  const messageHandler = async (type, payload) => {
    if (type === "idCheck.onApplicantSubmitted" || type === "idCheck.onApplicantResubmitted") {
      window.location.pathname = "/thank-you";
    }
    if (type === "idCheck.stepCompleted") {
      const sumsubStageTranslate = getText(`sumsub.${payload.step.toLowerCase()}`);
      const data = { stage_name: sumsubStageTranslate };
      const response = await fetch(`https://us.altaclub.vc/api/v1/investors/on_sumsub_stage_uploaded/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("us_access")}`,
        },
      });
    }
  };

  return (
    <div>
      {
        (store.profileUS.entity_type === 'Entity' || store.profileUS.investor_legal_type === 2) && (
          <PopupPaper style={{ margin: "50px auto 0 auto", padding: "50px" }}>
              <p>For further onboarding, please provide the following documents:</p>

              <p><strong><em>
                Documents of the director and UBO of the company (and for all shareholders who have more than or equal to 10% ownership)
              </em></strong></p>

              <ol>
                <li>
                  <p>Color photo of the first page of a general passport or ID card</p>
                  <p>IMPORTANT: All 4 corners of the document must be visible in the photo. Only original color photographs in jpg format are accepted; photo editing is not allowed. PDF format is not allowed.</p>
                </li>
                <li>
                  <p>Color photo Residence permit card (optional if the country of citizenship is different from the country of residence)</p>
                  <p>IMPORTANT: photos from both sides, in jpg format.</p>
                </li>
                <li>
                  <p>A document confirming the address of residence (this could be a utility bill, a lease agreement, a bank statement, documents from government agencies of the place of residence)</p>
                </li>
              </ol>

              <p>Documents for the director of the company</p>

              <ol>
                <li>
                  <p>Color photo of the first page of a general passport or ID card</p>
                  <p>IMPORTANT: All 4 corners of the document must be visible in the photo. Only original color photographs in jpg format are accepted; photo editing is not allowed. PDF format is not allowed.</p>
                </li>
                <li>
                  <p>Color photo Residence permit card (optional if the country of citizenship differs from the country of residence)</p>
                  <p>IMPORTANT: photos from both sides, in jpg format.</p>
                </li>
                <li>
                  <p>A document confirming the residential address (this could be a utility bill, driver’s license, rental agreement)</p>
                </li>
              </ol>

              <p><strong><em>
                Documents for the company:
              </em></strong></p>

              <ol>
                <li>
                  <p>Certificate of incorporation/registration</p>
                </li>
                <li>
                  <p>Memorandum/articles of incorporation/association/registration</p>
                </li>
                <li>
                  <p>Certificate of good standing</p>
                </li>
                <li>
                  <p>Director registry</p>
                </li>
                <li>
                  <p>Shareholder registry</p>
                </li>
              </ol>
              <p><strong><em>
                Mail to:&nbsp;
                <a href="mailto:anna@altaclub.vc" target="_blank" rel="noreferrer">Anna@altaclub.vc</a>
              </em></strong></p>
          </PopupPaper>
        ) 
      }
      {!(store.profileUS.entity_type === 'Entity' || store.profileUS.investor_legal_type === 2) && sumsubTokenResponse.complete && (
        <PopupPaper style={{ margin: "50px auto 0 auto" }}>
          <SumsubWebSdk
            accessToken={sumsubTokenResponse.data.token}
            expirationHandler={fetchSumsubToken}
            onMessage={messageHandler}
            config={{ lang: store.language }}
          />
        </PopupPaper>
      )}
    </div>
  );
});
