import React, { useRef } from "react";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import { InputLabel, Paper, Popper, Typography, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  popover: { padding: "10px 15px" },
  popper: {
    marginLeft: 10,
  },
  paper: {
    boxShadow: "0px 8px 12px 4px rgba(57, 110, 190, 0.1)",
  },
  error: {
    "& ul": {
      border: "1px solid #F36363 !important",
    },
  },
  arrowLeft: {
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: "10px solid white",
    position: "absolute",
    left: -8,
    top: "calc(50% - 10px)",
  },
});

const InputComponent = (props) => (
  <div>
    <InputBase
      {...props}
      startAdornment={
        !props.notflag && (
          <InputAdornment
            style={{
              position: "absolute",
              left: 16,
              zIndex: 1,
            }}
          >
            {props.value ? <span className={`flag-icon flag-icon-${props.value.toLowerCase()}`} /> : props.notflag}
          </InputAdornment>
        )
      }
    />
  </div>
);

export function Autocomplete({ value, onChange, options, caption, notflag, error, showAll = false }) {
  const rootEl = useRef(null);
  const classes = useStyles();

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div ref={rootEl}>
      <InputLabel style={{ fontSize: 14 }} className={classes.error}>
        {caption}
      </InputLabel>
      <Select
        className={error && classes.error}
        fullWidth
        value={value}
        onChange={handleChange}
        input={<InputComponent notflag={notflag} ref={rootEl} />}
      >
        {!showAll &&
          <MenuItem />
        }
        {showAll && 
          <MenuItem
            value='showAll'
          >
            Show all
          </MenuItem>
        }
        {options.map((option) => (
          <MenuItem
            style={{
              whiteSpace: "normal",
              maxWidth: "75ch",
            }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Popper open={Boolean(error)} anchorEl={rootEl.current} className={classes.popper} placement="bottom">
        <Paper className={classes.paper}>
          <div className={classes.arrowLeft} />
          <Typography variant="subtitle2" className={classes.popover}>
            {error}
          </Typography>
        </Paper>
      </Popper>
    </div>
  );
}
