import Axios from "axios";
import { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { REACT_APP_API_URL } from "./constants/selectConstants";
import useLanguage from "./languages/index";
import { useStore } from "./store";

const axios = Axios.create({
  baseURL: REACT_APP_API_URL || "/",
});

const useAsyncEndpoint = (fn, empty = {}) => {
  const store = useStore();
  const getText = useLanguage();

  const [res, setRes] = useState({
    data: empty,
    complete: false,
    pending: false,
    error: false,
  });
  const [req, setReq] = useState();

  useEffect(() => {
    if (!req) return;
    setRes({
      data: empty,
      pending: true,
      error: false,
      complete: false,
    });

    if (!req.noAuth)
      req.headers = { Authorization: `Bearer ${store.token.access}` };

    if (req.useUS) {
      req.headers = {
        Authorization: `Bearer ${localStorage.us_access}`,
      };
    }

    store.setLoading(true);
    axios(req)
      .then((res) => {
        store.setLoading(false);

        setRes({
          data: res.data,
          pending: false,
          error: false,
          complete: true,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          axios({
            method: "POST",
            url: req.useUS ? urls.refreshUS() : urls.refresh(),
            data: {
              refresh: req.useUS
                ? localStorage.us_refresh
                : store.token.refresh,
            },
          })
            .then((res) => {
              if (!req.useUS) {
                store.setToken(res.data, true);
              } else {
                localStorage.setItem("us_access", res.data.access);

                if (res.data.refresh) {
                  localStorage.setItem("us_refresh", res.data.refresh);
                }
              }
              req.headers = { Authorization: `Bearer ${res.data.access}` };
              setReq(null);
              setReq(req);
            })
            .catch((error) => {
              if (store.token.access && store.token.refresh) {
                NotificationManager.error(getText("messages.wrongToken"));
                store.clearToken();
              }
              store.setLoading(false);
            });
        }

        setRes({
          data: error.response && error.response.data,
          pending: false,
          error: true,
          complete: true,
        });
      });
  }, [req]);

  return [res, (...args) => setReq(fn(...args))];
};

const urls = {
  login() {
    return "/api/auth/token/";
  },
  register() {
    return "/api/v1/investors/register/";
  },
  info() {
    return "/api/auth/info/";
  },
  retrieve() {
    return "/api/auth/password/retrieve/";
  },
  refresh() {
    return "/api/auth/token/refresh/";
  },
  refreshUS() {
    return "https://us.altaclub.vc/api/auth/token/refresh/";
  },
  accounts(id) {
    return `/api/v1/investors/accounts/${id ? `${id}/` : ""}`;
  },
  profile() {
    return `/api/v1/investors/profile/`;
  },
  profileUS() {
    return `https://us.altaclub.vc/api/v1/investors/profile/`;
  },
  ubo() {
    return `/api/v1/investors/ubo/`;
  },
  usUbo() {
    return `https://us.altaclub.vc/api/v1/investors/ubo/`;
  },
  shareholder() {
    return `/api/v1/investors/shareholder/`;
  },
  director() {
    return `/api/v1/investors/director/`;
  },
  kyc() {
    return `/api/v1/investors/scenarios/kyc/`;
  },
  usProfileInfo() {
    return `https://us.altaclub.vc/api/v1/investors/scenarios/kyc/`;
  },
  sumsubToken() {
    return `/api/v1/sumsub/token/`;
  },
  sumsubTokenUS() {
    return `https://us.altaclub.vc/api/v1/sumsub/token/`;
  },
  secondaryMarket() {
    return `/api/v1/deals/secondary_market/portfolio/`;
  },
  secondaryApplications() {
    return "/api/v1/deals/secondary_market/";
  },
  secondaryMyApplications() {
    return "/api/v1/deals/secondary_market/me/";
  },
  secondaryCreateApplication() {
    return "/api/v1/deals/secondary_market/create/";
  },
  secondaryCancelApplication(id) {
    return `/api/v1/deals/secondary_market/${id}/cancel/`;
  },
  documents({ id, params }) {
    const idValue = `${id ? `${id}/` : ""}`;
    const paramsValue = `?${params ? `${params}` : ""}`;
    return `/api/v1/investors/documents/${idValue || paramsValue}`;
  },
  pdf(params, date) {
    return `https://sandbox.altaclub.vc/api/v1/investors/get_html_report/${params.id}/${date[0]}/${date[1]}/`;
  },
  documentsTypes() {
    return `/api/v1/investors/documents/types/`;
  },
  documentUpload(id) {
    return `/api/v1/investors/documents/${id}/`;
  },
  rounds(query, mode = "default") {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const isActive = query !== "closed";
    const isActiveParam = capitalizeFirstLetter(isActive.toString());

    return `/api/v1/rounds/cached_list/?active=${isActiveParam}&mode=${mode}`;
  },
  roundsReport() {
    return `/api/v1/rounds/list/`;
  },
  startupReport() {
    return `/api/v1/startups/list/`;
  },
  investorReport() {
    return `/api/v1/investors/list/`;
  },
  typeReport(id, type) {
    return `/api/v1/investors/get_${type}_report/${id}/`;
  },
  roundsSubscribe(id) {
    return `/api/v1/rounds/${id}/subscribe/`;
  },
  chartsRoi() {
    return `/api/v1/investors/charts/roi/`;
  },
  chartsVolume(useAssets = 1, usePending = 1) {
    return `/api/v1/investors/charts/total/?use_assets=${useAssets}&use_pending=${usePending}`;
  },
  quarterReports() {
    return `/api/v1/investors/reports/quarter/`;
  },
  transactionReport() {
    return `/api/v1/investors/reports/transaction-report/`;
  },
  ahsReport() {
    return `/api/v1/investors/reports/ahs-report/`;
  },
  tablesPortfolio() {
    return `/api/v1/investors/tables/portfolio/`;
  },
  notifications() {
    return `/api/v1/notifications/alerts/`;
  },
  notificationsParam(params) {
    return `/api/v1/notifications/alerts/?${params ? `${params}` : ""}`;
  },
  notificationsSummary() {
    return `/api/v1/notifications/summary/`;
  },
  notificationsTypes() {
    return `/api/v1/notifications/types/`;
  },
  alerts() {
    return `/api/v1/notifications/alerts/`;
  },
  alertsById(id) {
    return `/api/v1/notifications/alerts/${id ? `${id}/` : ""}`;
  },
  changePassword() {
    return `/api/auth/password/change/`;
  },
  lost(params) {
    return `/api/auth/password/lost/${params || ""}`;
  },
  getTelegramLink() {
    return "/api/v1/rounds/telegram/get_code/";
  },
  docusignDocuments() {
    return "/api/v1/investors/docusign/documents/";
  },
  news(params) {
    return `/api/v1/news/cached_list/?page_size=3&news_count=3&${params}`;
  },
  moreNews(startup) {
    return `/api/v1/news/by_startup/?startup=${startup}&page_size=8`;
  },
  reportNews(startup, params) {
    if (startup) {
      return `/api/v1/reports/news/${startup}/?${params}`;
    }

    return `/api/v1/reports/news/list/?${params}`;
  },
  startupList() {
    return "/api/v1/investors/actual_startup_list/";
  },
  mappingLogin() {
    return `/api/v1/investors/mapping_login/`;
  },
  mappingObtain() {
    return "/api/v1/investors/obtain_mapping_keypair/";
  },
  fatfCountry(countryCode) {
    return `/api/v1/investors/is_fatf_country/${countryCode}/`;
  },
  fcmToken() {
    return "/api/v1/fcm/devices/";
  },
  generatePdfReport() {
    return "/api/v1/reports/generate_pdf/";
  },
  pdfReportList(reportType) {
    return `/api/v1/reports/pdf_reports/?report_type=${reportType}`;
  },
  reportInstances() {
    return "/api/v1/investors/series_report/";
  },
  reportByInstance(instance) {
    return `/api/v1/investors/series_report/${instance}/`;
  },
  startupListByInstances() {
    return "/api/v1/investors/full_actual_startup_list/";
  },
  fullChartReport() {
    return "/api/v1/investors/series_report_full/";
  },
  cachedReportByInstance(instance, reportType, endDate, startDate, split) {
    const reportUri = `/api/v1/reports/cached_report/${reportType}/?instances=${instance}&end_date=${endDate}&split=${split}`;

    if (reportType === "transactional") {
      return `${reportUri}&start_date=${startDate}`;
    }

    return reportUri;
  },
};

export { useAsyncEndpoint };
export default urls;
