/* eslint-disable camelcase */
export const countries_en = {
  RU: "Russia",
  US: "United States of America",
  UA: "Ukraine",
  IL: "Izrael",
  UK: "United Kingdom",
  AC: "Ascension Island",
  AD: "Andorra",
  AE: "United Arab Emirates",
  AF: "Afghanistan",
  AG: "Antigua & Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AO: "Angola",
  AQ: "Antarctica",
  AR: "Argentina",
  AS: "American Samoa",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AX: "Åland Islands",
  AZ: "Azerbaijan",
  BA: "Bosnia & Herzegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "St. Barthélemy",
  BM: "Bermuda",
  BN: "Brunei",
  BO: "Bolivia",
  BQ: "Caribbean Netherlands",
  BR: "Brazil",
  BS: "Bahamas",
  BT: "Bhutan",
  BW: "Botswana",
  BY: "Belarus",
  BZ: "Belize",
  CA: "Canada",
  CC: "Cocos (Keeling) Islands",
  CD: "Congo - Kinshasa",
  CF: "Central African Republic",
  CG: "Congo - Brazzaville",
  CH: "Switzerland",
  CI: "Côte d’Ivoire",
  CK: "Cook Islands",
  CL: "Chile",
  CM: "Cameroon",
  CN: "China",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Cape Verde",
  CW: "Curaçao",
  CX: "Christmas Island",
  CY: "Cyprus",
  CZ: "Czechia",
  DE: "Germany",
  DG: "Diego Garcia",
  DJ: "Djibouti",
  DK: "Denmark",
  DM: "Dominica",
  DO: "Dominican Republic",
  DZ: "Algeria",
  EA: "Ceuta & Melilla",
  EC: "Ecuador",
  EE: "Estonia",
  EG: "Egypt",
  EH: "Western Sahara",
  ER: "Eritrea",
  ES: "Spain",
  ET: "Ethiopia",
  EZ: "Eurozone",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands",
  FM: "Micronesia",
  FO: "Faroe Islands",
  FR: "France",
  GA: "Gabon",
  GB: "United Kingdom",
  GD: "Grenada",
  GE: "Georgia",
  GF: "French Guiana",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Greenland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Equatorial Guinea",
  GR: "Greece",
  GS: "South Georgia & South Sandwich Islands",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong SAR China",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  IC: "Canary Islands",
  ID: "Indonesia",
  IE: "Ireland",
  IM: "Isle of Man",
  IN: "India",
  IO: "British Indian Ocean Territory",
  IQ: "Iraq",
  IR: "Iran",
  IS: "Iceland",
  IT: "Italy",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordan",
  JP: "Japan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KI: "Kiribati",
  KM: "Comoros",
  KN: "St. Kitts & Nevis",
  KP: "North Korea",
  KR: "South Korea",
  KW: "Kuwait",
  KY: "Cayman Islands",
  KZ: "Kazakhstan",
  LA: "Laos",
  LB: "Lebanon",
  LC: "St. Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  LY: "Libya",
  MA: "Morocco",
  MC: "Monaco",
  MD: "Moldova",
  ME: "Montenegro",
  MF: "St. Martin",
  MG: "Madagascar",
  MH: "Marshall Islands",
  MK: "Macedonia",
  ML: "Mali",
  MM: "Myanmar (Burma)",
  MN: "Mongolia",
  MO: "Macau SAR China",
  MP: "Northern Mariana Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Maldives",
  MW: "Malawi",
  MX: "Mexico",
  MY: "Malaysia",
  MZ: "Mozambique",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Netherlands",
  NO: "Norway",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "New Zealand",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "French Polynesia",
  PG: "Papua New Guinea",
  PH: "Philippines",
  PK: "Pakistan",
  PL: "Poland",
  PM: "St. Pierre & Miquelon",
  PN: "Pitcairn Islands",
  PR: "Puerto Rico",
  PS: "Palestinian Territories",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RS: "Serbia",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SB: "Solomon Islands",
  SC: "Seychelles",
  SD: "Sudan",
  SE: "Sweden",
  SG: "Singapore",
  SH: "St. Helena",
  SI: "Slovenia",
  SJ: "Svalbard & Jan Mayen",
  SK: "Slovakia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Suriname",
  SS: "South Sudan",
  ST: "São Tomé & Príncipe",
  SV: "El Salvador",
  SX: "Sint Maarten",
  SY: "Syria",
  SZ: "Swaziland",
  TA: "Tristan da Cunha",
  TC: "Turks & Caicos Islands",
  TD: "Chad",
  TF: "French Southern Territories",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TL: "Timor-Leste",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Turkey",
  TT: "Trinidad & Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzania",
  UG: "Uganda",
  UM: "U.S. Outlying Islands",
  UN: "United Nations",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Vatican City",
  VC: "St. Vincent & Grenadines",
  VE: "Venezuela",
  VG: "British Virgin Islands",
  VI: "U.S. Virgin Islands",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis & Futuna",
  WS: "Samoa",
  XK: "Kosovo",
  YE: "Yemen",
  YT: "Mayotte",
  ZA: "South Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const countries_ru = {
  AU: "Австралия",
  AT: "Австрия",
  AZ: "Азербайджан",
  AX: "Аландские о-ва",
  AL: "Албания",
  DZ: "Алжир",
  AS: "Американское Самоа",
  AI: "Ангилья",
  AO: "Ангола",
  AD: "Андорра",
  AQ: "Антарктида",
  AG: "Антигуа и Барбуда",
  AR: "Аргентина",
  AM: "Армения",
  AW: "Аруба",
  AF: "Афганистан",
  BS: "Багамы",
  BD: "Бангладеш",
  BB: "Барбадос",
  BH: "Бахрейн",
  BY: "Беларусь",
  BZ: "Белиз",
  BE: "Бельгия",
  BJ: "Бенин",
  BM: "Бермуды",
  BG: "Болгария",
  BO: "Боливия",
  BQ: "Бонэйр, Синт-Эстатиус и Саба",
  BA: "Босния и Герцеговина",
  BW: "Ботсвана",
  BR: "Бразилия",
  IO: "Британская территория в Индийском океане",
  BN: "Бруней-Даруссалам",
  BF: "Буркина-Фасо",
  BI: "Бурунди",
  BT: "Бутан",
  VU: "Вануату",
  VA: "Ватикан",
  GB: "Великобритания",
  HU: "Венгрия",
  VE: "Венесуэла",
  VG: "Виргинские о-ва (Британские)",
  VI: "Виргинские о-ва (США)",
  UM: "Внешние малые о-ва (США)",
  TL: "Восточный Тимор",
  VN: "Вьетнам",
  GA: "Габон",
  HT: "Гаити",
  GY: "Гайана",
  GM: "Гамбия",
  GH: "Гана",
  GP: "Гваделупа",
  GT: "Гватемала",
  GN: "Гвинея",
  GW: "Гвинея-Бисау",
  DE: "Германия",
  GG: "Гернси",
  GI: "Гибралтар",
  HN: "Гондурас",
  HK: "Гонконг (специальный административный район)",
  GD: "Гренада",
  GL: "Гренландия",
  GR: "Греция",
  GE: "Грузия",
  GU: "Гуам",
  DK: "Дания",
  JE: "Джерси",
  DJ: "Джибути",
  DG: "Диего-Гарсия",
  DM: "Доминика",
  DO: "Доминиканская Республика",
  EG: "Египет",
  ZM: "Замбия",
  EH: "Западная Сахара",
  ZW: "Зимбабве",
  IL: "Израиль",
  IN: "Индия",
  ID: "Индонезия",
  JO: "Иордания",
  IQ: "Ирак",
  IR: "Иран",
  IE: "Ирландия",
  IS: "Исландия",
  ES: "Испания",
  IT: "Италия",
  YE: "Йемен",
  CV: "Кабо-Верде",
  KZ: "Казахстан",
  KY: "Каймановы о-ва",
  KH: "Камбоджа",
  CM: "Камерун",
  CA: "Канада",
  IC: "Канарские о-ва",
  QA: "Катар",
  KE: "Кения",
  CY: "Кипр",
  KG: "Киргизия",
  KI: "Кирибати",
  CN: "Китай",
  KP: "КНДР",
  CC: "Кокосовые о-ва",
  CO: "Колумбия",
  KM: "Коморы",
  CG: "Конго - Браззавиль",
  CD: "Конго - Киншаса",
  XK: "Косово",
  CR: "Коста-Рика",
  CI: "Кот-д’Ивуар",
  CU: "Куба",
  KW: "Кувейт",
  CW: "Кюрасао",
  LA: "Лаос",
  LV: "Латвия",
  LS: "Лесото",
  LR: "Либерия",
  LB: "Ливан",
  LY: "Ливия",
  LT: "Литва",
  LI: "Лихтенштейн",
  LU: "Люксембург",
  MU: "Маврикий",
  MR: "Мавритания",
  MG: "Мадагаскар",
  YT: "Майотта",
  MO: "Макао (специальный административный район)",
  MK: "Македония",
  MW: "Малави",
  MY: "Малайзия",
  ML: "Мали",
  MV: "Мальдивы",
  MT: "Мальта",
  MA: "Марокко",
  MQ: "Мартиника",
  MH: "Маршалловы Острова",
  MX: "Мексика",
  MZ: "Мозамбик",
  MD: "Молдова",
  MC: "Монако",
  MN: "Монголия",
  MS: "Монтсеррат",
  MM: "Мьянма (Бирма)",
  NA: "Намибия",
  NR: "Науру",
  NP: "Непал",
  NE: "Нигер",
  NG: "Нигерия",
  NL: "Нидерланды",
  NI: "Никарагуа",
  NU: "Ниуэ",
  NZ: "Новая Зеландия",
  NC: "Новая Каледония",
  NO: "Норвегия",
  AC: "о-в Вознесения",
  IM: "о-в Мэн",
  NF: "о-в Норфолк",
  CX: "о-в Рождества",
  SH: "о-в Св. Елены",
  TC: "о-ва Тёркс и Кайкос",
  AE: "ОАЭ",
  OM: "Оман",
  UN: "Организация Объединенных Наций",
  CK: "Острова Кука",
  PN: "острова Питкэрн",
  PK: "Пакистан",
  PW: "Палау",
  PS: "Палестинские территории",
  PA: "Панама",
  PG: "Папуа – Новая Гвинея",
  PY: "Парагвай",
  PE: "Перу",
  PL: "Польша",
  PT: "Португалия",
  PR: "Пуэрто-Рико",
  KR: "Республика Корея",
  RE: "Реюньон",
  RU: "Россия",
  RW: "Руанда",
  RO: "Румыния",
  SV: "Сальвадор",
  WS: "Самоа",
  SM: "Сан-Марино",
  ST: "Сан-Томе и Принсипи",
  SA: "Саудовская Аравия",
  SZ: "Свазиленд",
  MP: "Северные Марианские о-ва",
  SC: "Сейшельские Острова",
  BL: "Сен-Бартелеми",
  MF: "Сен-Мартен",
  PM: "Сен-Пьер и Микелон",
  SN: "Сенегал",
  VC: "Сент-Винсент и Гренадины",
  KN: "Сент-Китс и Невис",
  LC: "Сент-Люсия",
  RS: "Сербия",
  EA: "Сеута и Мелилья",
  SG: "Сингапур",
  SX: "Синт-Мартен",
  SY: "Сирия",
  SK: "Словакия",
  SI: "Словения",
  US: "Соединенные Штаты",
  SB: "Соломоновы Острова",
  SO: "Сомали",
  SD: "Судан",
  SR: "Суринам",
  SL: "Сьерра-Леоне",
  TJ: "Таджикистан",
  TH: "Таиланд",
  TW: "Тайвань",
  TZ: "Танзания",
  TG: "Того",
  TK: "Токелау",
  TO: "Тонга",
  TT: "Тринидад и Тобаго",
  TA: "Тристан-да-Кунья",
  TV: "Тувалу",
  TN: "Тунис",
  TM: "Туркменистан",
  TR: "Турция",
  UG: "Уганда",
  UZ: "Узбекистан",
  UA: "Украина",
  WF: "Уоллис и Футуна",
  UY: "Уругвай",
  FO: "Фарерские о-ва",
  FM: "Федеративные Штаты Микронезии",
  FJ: "Фиджи",
  PH: "Филиппины",
  FI: "Финляндия",
  FK: "Фолклендские о-ва",
  FR: "Франция",
  GF: "Французская Гвиана",
  PF: "Французская Полинезия",
  TF: "Французские Южные территории",
  HR: "Хорватия",
  CF: "ЦАР",
  TD: "Чад",
  ME: "Черногория",
  CZ: "Чехия",
  CL: "Чили",
  CH: "Швейцария",
  SE: "Швеция",
  SJ: "Шпицберген и Ян-Майен",
  LK: "Шри-Ланка",
  EC: "Эквадор",
  GQ: "Экваториальная Гвинея",
  ER: "Эритрея",
  EE: "Эстония",
  ET: "Эфиопия",
  ZA: "ЮАР",
  GS: "Южная Георгия и Южные Сандвичевы о-ва",
  SS: "Южный Судан",
  JM: "Ямайка",
  JP: "Япония",
  EZ: "Eurozone",
};
