import { makeStyles, withStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";

export const HeaderCell = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    paddingRight: "1em",
  },
}))(Box);

export const useStylesSecondary = makeStyles((theme) => ({
  empty: {
    position: "absolute",
    height: "calc(100vh - 87px - 120px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 110px)",
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 56px",
    },
  },
  logo: {
    maxWidth: 110,
  },
  paper: {
    height: 96,
    paddingLeft: 30,
    marginTop: 8,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    padding: "10px",
    position: "sticky",
    top: "87px",
    zIndex: "5",
    backgroundColor: theme.palette.background.default,
  },
  morebuttons: {
    "&:first-child": {
      marginBottom: "10px",
    },
  },
}));
