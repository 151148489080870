import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Checkbox, FormControlLabel, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { NotificationManager } from "react-notifications";
import validate from "validate.js";
import ym from "react-yandex-metrika";
import { useStore } from "../../../store";
import TextInput from "../../../components/TextInput";
import useLanguage from "../../../languages";
import PopupPaper from "../../../components/PopupPaper";
import { postCreditionals, registerUser } from "../../../api/api";
import { REACT_APP_API_URL } from "../../../constants/selectConstants";

const useStyles = makeStyles((theme) => ({
  input: { marginTop: 28 },
  button: {
    width: 190,
    padding: "12px 0",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center",
    justifyContent: "center",
  },
  body: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

const initialState = {
  name: "",
  nameEn: "",
  surname: "",
  surnameEn: "",
  email: "",
  password: "",
  confirmPassword: "",
  voice: "",
  telegram: "",
  errors: {},
  remember: true,
};

export default observer(({ history, location }) => {
  const getText = useLanguage();
  const desktop = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const store = useStore();
  const [state, setState] = useState(initialState);
  const [telegramCheck, setTelegramCheck] = useState(false);
  const [resp, register] = registerUser();
  const [token, login] = postCreditionals();

  useEffect(() => {
    setState({ ...state, errors: {} });

    if (store.language === "ru") {
      history.push("/menu");
    } else {
      history.push("/menu");
    }
  }, [store.language]);

  useEffect(() => {
    login({ email: state.email, password: state.password });
  }, [resp]);

  const personalConstraintsEn = {
    nameEn: {
      presence: {
        allowEmpty: false,
        message: `^${getText("pages.signUp.validation.notEmpty")}`,
      },
      length: {
        minimum: 1,
        message: `^${getText("pages.signUp.validation.notEmpty", 1)}`,
      },
      format: {
        pattern: "[a-z]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.nameEnFormat")}`,
      },
    },
    surnameEn: {
      presence: {
        allowEmpty: false,
        message: `^${getText("pages.signUp.validation.notEmpty")}`,
      },
      length: { minimum: 1, message: `^${getText("errors.passwordShort", 1)}` },
      format: {
        pattern: "[a-z]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.surnameEnFormat")}`,
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.emailEmpty")}`,
      },
      length: { minimum: 1, message: `^${getText("errors.emailInvalid", 1)}` },
      format: {
        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.emailFormat")}`,
      },
    },
    password: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.passwordEmpty")}`,
      },
      length: { minimum: 8, message: `^${getText("errors.passwordShort", 8)}` },
      format: {
        pattern: "^(?=\\S*[a-z])(?=\\S*[A-Z])(?=\\S*\\d)\\S*$",
        flags: "g",
        message: `^${getText("pages.settings.password.messages.validation.2")}`,
      },
    },
    confirmPassword: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.passwordEmpty")}`,
      },
      equality: {
        attribute: "password",
        message: `^${getText("pages.settings.password.messages.validation.3")}`,
        comparator(v1, v2) {
          return v1 === v2;
        },
      },
    },
    voice: {
      presence: {
        allowEmpty: false,
        message: `^${getText("pages.signUp.validation.phoneEmpty")}`,
      },
      length: {
        minimum: 10,
        message: `^${getText("pages.signUp.validation.phoneMin", 10)}`,
      },
    },
  };

  const personalConstraints = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${getText("pages.signUp.validation.notEmpty")}`,
      },
      length: {
        minimum: 1,
        message: `^${getText("pages.signUp.validation.nameFormat", 1)}`,
      },
      format: {
        pattern: "[а-яa-z]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.nameFormat")}`,
      },
    },
    surname: {
      presence: {
        allowEmpty: false,
        message: `^${getText("pages.signUp.validation.notEmpty")}`,
      },
      length: {
        minimum: 1,
        message: `^${getText("pages.signUp.validation.notEmpty", 1)}`,
      },
      format: {
        pattern: "[а-яa-z]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.surnameFormat")}`,
      },
    },
  };

  const handleChange = (prop) => (event) => {
    if (telegramCheck === true && prop === "voice") {
      setState({
        ...state,
        [prop]: event.target.value,
        telegram: event.target.value,
      });
      return;
    }
    setState({ ...state, [prop]: event.target.value });
  };

  const handleClickCancel = () => {
    history.push("/login");
  };

  async function registerInvestor(data) {
    const email = data.email.toLowerCase().trim()

    const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/validate_email/${email}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const resp = await result.json();
    if (resp.in_use) {
      NotificationManager.error(getText("pages.settings.password.messages.emailInUse"));
    } else {

      const userData = { ...data }
      userData.email = userData.email.toLowerCase().trim()

      const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/register/`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }

  async function authorizeInvestor(data) {
    const userData = { ...data }
    userData.email = userData.email.toLowerCase().trim()

    const result = await fetch(`${REACT_APP_API_URL}/api/auth/token/`, {
      method: "POST",
      body: JSON.stringify(userData),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (result.status !== 200) {
      // TODO обработать ошибки
    }
    const json = await result.json();

    store.setToken(json, true);
  }

  const onSubmit = async (event) => {
    ym(80100502, "reachGoal", "sign-up-1");
    event.preventDefault();
    const errors = validate(state, store.language === "ru" ? personalConstraints : personalConstraintsEn);

    if (!errors) {
      setState({ ...state, errors: {} });
      const userData = {
        email: state.email,
        name_ru: state.name,
        name_en: state.nameEn,
        password: state.password,
        surname_ru: state.surname,
        surname_en: state.surnameEn,
        telegram: state.telegram,
        phone: state.voice,
        language: store.language,
      };

      await registerInvestor(userData);
      history.push({
        pathname: "/sign-up/secondary",
      });
    } else {
      NotificationManager.error(getText("pages.settings.password.messages.errorFormat"));
      setState({ ...state, errors: errors || {} });
    }
  };

  const onSubmitNow = async (event) => {
    event.preventDefault();
    const errors = validate(
      state,
      store.language === "ru" ? { ...personalConstraints, ...personalConstraintsEn } : personalConstraintsEn
    );

    if (!errors) {
      const userReg = {
        email: state.email,
        name_ru: state.name,
        name_en: state.nameEn,
        password: state.password,
        surname_ru: state.surname,
        surname_en: state.surnameEn,
        telegram: state.telegram,
        phone: state.voice,
        language: store.language,
      };
      await registerInvestor(userReg);
      await authorizeInvestor({
        email: state.email,
        password: state.password,
      });
      history.push("/settings/profile");
    } else {
      NotificationManager.error(getText("pages.settings.password.messages.errorFormat"));
      setState({ ...state, errors: errors || {} });
    }
  };

  useEffect(() => {
    if (resp.error) {
      NotificationManager.error("Connection problems");
    }
  }, [resp]);

  const telegramChecked = () => {
    setTelegramCheck(!telegramCheck);
    state.telegram = telegramCheck ? "" : state.voice;
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <PopupPaper popup caption={getText("pages.signUp.caption")}>
        <form>
          {store.language === "ru" && (
            <>
              <TextInput
                type="text"
                error={state.errors.name}
                className={classes.input}
                value={state.name}
                onChange={handleChange("name")}
                caption={getText("pages.signUp.name")}
              />
              <TextInput
                type="text"
                error={state.errors.surname}
                className={classes.input}
                value={state.surname}
                onChange={handleChange("surname")}
                caption={getText("pages.signUp.surname")}
              />
            </>
          )}
          <TextInput
            type="text"
            error={state.errors.nameEn}
            className={classes.input}
            value={state.nameEn}
            onChange={handleChange("nameEn")}
            caption={getText("pages.signUp.nameEn")}
          />
          <TextInput
            type="text"
            error={state.errors.surnameEn}
            className={classes.input}
            value={state.surnameEn}
            onChange={handleChange("surnameEn")}
            caption={getText("pages.signUp.surnameEn")}
          />
          <TextInput
            type="email"
            error={state.errors.email}
            className={classes.input}
            value={state.email}
            onChange={handleChange("email")}
            caption={getText("pages.signUp.email")}
          />
          <TextInput
            type="password"
            error={state.errors.password}
            className={classes.input}
            value={state.password}
            onChange={handleChange("password")}
            caption={getText("pages.signUp.newPassword")}
          />
          <TextInput
            type="password"
            error={state.errors.confirmPassword}
            className={classes.input}
            value={state.confirmPassword}
            onChange={handleChange("confirmPassword")}
            caption={getText("pages.signUp.confirmPassword")}
          />
          <TextInput
            type="number"
            error={state.errors.voice}
            className={classes.input}
            value={state.voice}
            onChange={handleChange("voice")}
            caption={getText("pages.signUp.voice")}
          />
          <FormControlLabel
            style={{ marginTop: 10 }}
            label={getText("pages.signUp.telegramCheck")}
            control={<Checkbox onChange={telegramChecked} color="primary" style={{ marginRight: 10 }} />}
          />
          {!telegramCheck && (
            <TextInput
              type="number"
              error={state.errors.telegram}
              className={classes.input}
              value={state.telegram}
              onChange={handleChange("telegram")}
              caption={getText("pages.signUp.telegram")}
            />
          )}
          {/* <Box mt={3} display="flex" justifyContent="space-between" style={{marginTop: 50}}> */}
          {/* <Button */}
          {/*        disabled={!checkStatusDocuments} */}
          {/*        type="submit" */}
          {/*        onClick={onSubmit} */}
          {/*        fullWidth */}
          {/*        size="large" */}
          {/*        variant="contained" */}
          {/*        color="primary" */}
          {/*        className={classes.button} */}
          {/*        style={{width: "100%"}} */}
          {/*    > */}
          {/*        {getText('pages.signUp.continue')} */}
          {/*    </Button> */}
          {/* </Box> */}
          <Box mt={1} display="flex" justifyContent="space-between" style={{ marginTop: 50 }}>
            <Button
              type="button"
              onClick={onSubmitNow}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ width: "100%" }}
            >
              {getText("pages.signUp.registerNow")}
            </Button>
          </Box>
        </form>
      </PopupPaper>
    </div>
  );
});
