import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Collapse, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toJS } from "mobx";
import uuid from "react-uuid";
import { useRounds, useStartup, useInvestor, useTypeReport } from "../../api/api";
import PopupPaper from "../../components/PopupPaper";
import useLanguage from "../../languages";
import { useStore } from "../../store";
import { Autocomplete } from "../../components/Autocomplete";
import { AutocompleteMaterial } from "../../components/AutocompleteMaterial";
import { REACT_APP_API_URL } from "../../constants/selectConstants";

const FILTER_TYPE = ["investor", "startup", "rounds"];

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#FFFFFF",
    margin: 30,
    padding: 30,
  },
  details: {
    marginBottom: 10,
  },
  autoComplete: theme.autoComplete,
  finalHeadtCell: {
    color: "#7E9FC1",
  },
  whitePadding: {
    marginBottom: 10,
  },
  paddingZeroLeft: {
    paddingLeft: 0,
  },
  borderStartupColor: {
    borderBottom: "1px solid rgb(114 114 114)",
  },
}));

export default observer(() => {
  const getText = useLanguage();
  const classes = useStyles();
  const store = useStore();

  const [filterType, setFilterType] = useState();

  const [rounds, fetchRounds] = useRounds();
  const [getRounds, setRounds] = useState([]);

  const [startup, fetchStartup] = useStartup();
  const [getStartup, setStartup] = useState([]);

  const [investor, fetchInvestor] = useInvestor();
  const [getInvestor, setInvestor] = useState([]);

  const [typeReport, fetchTypeReport] = useTypeReport();

  useEffect(() => {
    fetchRounds();
    fetchStartup();
    fetchInvestor();
  }, []);

  useEffect(() => {
    const data = toJS(store.rounds);
    data.map((obj) => [obj]);
    setRounds([...data]);
  }, [store.rounds]);

  useEffect(() => {
    const data = toJS(store.startup);
    data.map((obj) => [obj]);
    setStartup([...data]);
  }, [store.startup]);

  useEffect(() => {
    const data = toJS(store.investor);
    data.map((obj) => [obj]);
    setInvestor([...data]);
  }, [store.investor]);

  useEffect(() => {
    if (rounds.complete) {
      store.setRounds(rounds.data);
    }
  }, [rounds]);

  useEffect(() => {
    if (startup.complete) {
      store.setStartup(startup.data);
    }
  }, [startup]);

  useEffect(() => {
    if (investor.complete) {
      store.setInvestor(investor.data);
    }
  }, [investor]);

  const handleChangeGetType = (prop, value) => {
    fetchTypeReport(value, prop);
  };

  const handleChangeFilterType = (prop) => (event) => {
    setFilterType(event.target.value);
  };

  const handleDate = (date) => {
    const result = new Date(date);
    return result;
  };

  return (
    <>
      <PopupPaper inline style={{ margin: "0 auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              notflag
              options={FILTER_TYPE.map((el) => ({ label: getText(`pages.report.select.${el}`), value: el }))}
              caption={getText("pages.report.filter")}
              onChange={handleChangeFilterType()}
            />
            {filterType === "startup" && (
              <Box className={classes.select} id="startup">
                <AutocompleteMaterial
                  notFlag
                  options={getStartup.map((el) => ({ label: getText(`${el.label}`), value: el.pk }))}
                  text="startup"
                  title="pages.report.select.startup"
                  onChange={handleChangeGetType}
                />
              </Box>
            )}
            {filterType === "rounds" && (
              <Box className={classes.select} id="round">
                <AutocompleteMaterial
                  notFlag
                  options={getRounds.map((el) => ({ label: getText(`${el.label}`), value: el.label }))}
                  text="round"
                  title="pages.report.select.rounds"
                  onChange={handleChangeGetType}
                />
              </Box>
            )}
            {filterType === "investor" && (
              <Box className={classes.select} id="investor">
                <AutocompleteMaterial
                  notFlag
                  options={getInvestor.map((el) => ({ label: getText(`${el.label}`), value: el.pk }))}
                  text="investor"
                  title="pages.report.select.investor"
                  onChange={handleChangeGetType}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </PopupPaper>
      {/* Rounds */}
      {/* Small table */}
      {typeReport.data.round && (
        <Box className={classes.container}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{getText("pages.report.selectRound.start_date")}</TableCell>
                <TableCell>{getText("pages.report.selectRound.finish_date")}</TableCell>
                <TableCell>{getText("pages.report.selectRound.post_money_evaluation")}</TableCell>
                <TableCell>{getText("pages.report.selectRound.pre_total_shares_fd")}</TableCell>
                <TableCell>{getText("pages.report.selectRound.post_total_shares_fd")}</TableCell>
                <TableCell>{getText("pages.report.selectRound.pre_money_evaluation")}</TableCell>
                <TableCell>{getText("pages.report.selectRound.paid_investments_sum")}</TableCell>
                <TableCell>{getText("pages.report.selectRound.signed_bundles_sum")}</TableCell>
                <TableCell>{getText("pages.report.selectRound.commitments_sum")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {typeReport.complete && typeReport.data.round && (
                <>
                  <TableRow>
                    <TableCell id="col_1">
                      <Typography variant="body2">
                        {typeReport.data.round_start_date || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_2">
                      <Typography variant="body2">
                        {typeReport.data.round_finish_date || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_3">
                      <Typography variant="body2">
                        {typeReport.data.post_money_evaluation || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_4">
                      <Typography variant="body2">
                        {typeReport.data.pre_total_shares_fd || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_5">
                      <Typography variant="body2">
                        {typeReport.data.post_total_shares_fd || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_6">
                      <Typography variant="body2">
                        {typeReport.data.pre_money_evaluation || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_7">
                      <Typography variant="body2">
                        {typeReport.data.paid_investments_sum || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_8">
                      <Typography variant="body2">
                        {typeReport.data.signed_bundles_sum || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_9">
                      <Typography variant="body2">
                        {typeReport.data.commitments_sum || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </Box>
      )}

      <Box className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {!((typeReport.data.us && typeReport.data.us.startup) || (typeReport.data.bo && typeReport.data.bo.startup)) &&
              !((typeReport.data.us && typeReport.data.us.round) || (typeReport.data.bo && typeReport.data.bo.round)) && (
                <TableCell>{getText("pages.report.select.startup")}</TableCell>
              )}
              {!((typeReport.data.us && typeReport.data.us.investor) || (typeReport.data.bo && typeReport.data.bo.investor)) && <TableCell>{getText("pages.report.select.investor")}</TableCell>}
              {((typeReport.data.us && typeReport.data.us.round) || (typeReport.data.bo && typeReport.data.bo.round)) && <TableCell>{getText("pages.report.select.seriesNumber")}</TableCell>}
              {((typeReport.data.us && typeReport.data.us.investor) || (typeReport.data.bo && typeReport.data.bo.investor)) && <TableCell>{getText("pages.report.select.rounds")}</TableCell>}
              {((typeReport.data.us && typeReport.data.us.round) || (typeReport.data.bo && typeReport.data.bo.round)) && <TableCell>{getText("pages.report.select.reportBundleAmount")}</TableCell>}
              {((typeReport.data.us && typeReport.data.us.round) || (typeReport.data.bo && typeReport.data.bo.round)) && <TableCell>{getText("pages.report.select.reportPaymentsReceived")}</TableCell>}
              {!((typeReport.data.us && typeReport.data.us.round) || (typeReport.data.bo && typeReport.data.bo.round)) && <TableCell>{getText("pages.report.select.amount")}</TableCell>}
              <TableCell>{getText("pages.report.select.bundle_signed")}</TableCell>
              <TableCell>{getText("pages.report.select.payment_received")}</TableCell>
              <TableCell>{getText("pages.report.select.securities_bought")}</TableCell>
              <TableCell>{getText("pages.report.select.cancelation")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Investors */}
            {typeReport.complete &&
              ((typeReport.data.us && typeReport.data.us.investor) || (typeReport.data.bo && typeReport.data.bo.investor)) &&
              Object.entries(typeReport.data).map(([instance, data]) => data && data.startups.map((startup, index) => (
                startup.rounds.map((round) =>
                  round.deal_rows.map((deal) => (
                    <TableRow key={uuid()}>
                      <TableCell id="col_1">
                        <Typography variant="body2">
                          {index + 1}) {startup.startup}
                        </Typography>
                      </TableCell>
                      <TableCell id="col_2">
                        <Typography variant="body2">
                          {/* ${round.round_level} */}
                          {`${round.round}` || getText("pages.report.notFound")}
                        </Typography>
                      </TableCell>
                      <TableCell id="col_3">
                        <Typography variant="body2">
                          {deal.payments_received_amount || getText("pages.report.pending")}
                        </Typography>
                      </TableCell>
                      <TableCell id="col_4">
                        <Typography variant="body2">{deal.bundle_signed_date || getText("pages.report.pending")}</Typography>
                      </TableCell>
                      <TableCell id="col_5">
                        <Typography variant="body2">{deal.payment_received_date || getText("pages.report.pending")}</Typography>
                      </TableCell>
                      <TableCell id="col_6">
                        <Typography variant="body2">{deal.securities_bought_date || getText("pages.report.pending")}</Typography>
                      </TableCell>
                      <TableCell id="col_7">
                        <Typography variant="body2">{deal.cancellation_date || getText("pages.report.notFound")}</Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )
              )))}

            {/* Rounds */}
            {/* Big table */}
            {typeReport.complete &&
              ((typeReport.data.us && typeReport.data.us.round) || (typeReport.data.bo && typeReport.data.bo.round)) &&
              Object.entries(typeReport.data).map(([instance, data]) => data && data.deal_rows.map((startup) => (
                <TableRow key={uuid()}>
                  <TableCell id="col_1">
                    <Typography variant="body2">{startup.investor || getText("pages.report.notFound")}</Typography>
                  </TableCell>
                  <TableCell id="col_1">
                    <Typography variant="body2">{(typeReport.data.us || typeReport.data.bo).series_number || getText("pages.report.notFound")}</Typography>
                  </TableCell>
                  <TableCell id="col_2">
                    <Typography variant="body2">{startup.bundle_signed_amount || getText("pages.report.notFound")}</Typography>
                  </TableCell>
                  <TableCell id="col_3">
                    <Typography variant="body2">
                      {startup.payments_received_amount || getText("pages.report.notFound")}
                    </Typography>
                  </TableCell>
                  <TableCell id="col_4">
                    <Typography variant="body2">{startup.bundle_signed_date || getText("pages.report.pending")}</Typography>
                  </TableCell>
                  <TableCell id="col_5">
                    <Typography variant="body2">{startup.payment_received_date || getText("pages.report.pending")}</Typography>
                  </TableCell>
                  <TableCell id="col_6">
                    <Typography variant="body2">{startup.securities_bought_date || getText("pages.report.pending")}</Typography>
                  </TableCell>
                  <TableCell id="col_7">
                    <Typography variant="body2">{startup.cancellation_date || getText("pages.report.notFound")}</Typography>
                  </TableCell>
                </TableRow>
              )))}
            {/* Startup */}
            {/* Big table */}
            {typeReport.complete &&
              ((typeReport.data.us && typeReport.data.us.startup) || (typeReport.data.bo && typeReport.data.bo.startup)) &&
              Object.entries(typeReport.data).map(([instance, data]) => data && data.rounds.map((round) => (
                <>
                  {round.deal_rows.map((deal) => (
                    <>
                      <TableRow key={uuid()}>
                        <TableCell id="col_1">{deal.investor || getText("pages.report.notFound")}</TableCell>
                        <TableCell id="col_2">{deal.payments_received_amount || getText("pages.report.pending")}</TableCell>
                        <TableCell id="col_3">{deal.bundle_signed_date || getText("pages.report.pending")}</TableCell>
                        <TableCell id="col_4">{deal.payment_received_date || getText("pages.report.pending")}</TableCell>
                        <TableCell id="col_5">{deal.securities_bought_date || getText("pages.report.pending")}</TableCell>
                        <TableCell id="col_6">{deal.cancellation_date || getText("pages.report.notFound")}</TableCell>
                      </TableRow>
                    </>
                  ))}
                  <TableRow>
                    <TableCell id="col_last_1" className={classes.paddingZeroLeft}>
                      <Typography variant="body2">
                        <p className={classes.finalHeadtCell}>{getText("pages.report.select.bundleSigned")}</p>
                        {round.bundles_sum || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_last_2" className={classes.paddingZeroLeft}>
                      <Typography variant="body2">
                        <p className={classes.finalHeadtCell}>{getText("pages.report.select.paymentReceived")}</p>
                        {round.payments_received_sum || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                    <TableCell id="col_last_3" className={classes.paddingZeroLeft}>
                      <Typography variant="body2">
                        <p className={classes.finalHeadtCell}>{getText("pages.report.select.waitingSum")}</p>
                        {round.waiting_sum || getText("pages.report.notFound")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={8} className={classes.borderStartupColor}>
                      <CollapseStartupTable round={round} />
                    </TableCell>
                  </TableRow>
                </>
              )))}

            {/* Total amount - US */}
            {typeReport.complete && typeReport.data.us && !typeReport.data.us.startup && (
              <>
                <Box mt={2}>
                  <strong>USA:</strong>
                </Box>
                <TableRow>
                  <TableCell id="col_last_1" className={classes.paddingZeroLeft}>
                    <Typography variant="body2">
                      <p className={classes.finalHeadtCell}>{getText("pages.report.select.bundleSigned")}</p>
                      {typeReport.data.us.bundles_sum || getText("pages.report.notFound")}
                    </Typography>
                  </TableCell>
                  <TableCell id="col_last_2" className={classes.paddingZeroLeft}>
                    <Typography variant="body2">
                      <p className={classes.finalHeadtCell}>{getText("pages.report.select.paymentReceived")}</p>
                      {typeReport.data.us.payments_received_sum || getText("pages.report.notFound")}
                    </Typography>
                  </TableCell>
                  <TableCell id="col_last_3" className={classes.paddingZeroLeft}>
                    <Typography variant="body2">
                      <p className={classes.finalHeadtCell}>{getText("pages.report.select.waitingSum")}</p>
                      {typeReport.data.us.waiting_sum || getText("pages.report.notFound")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}

            {/* Total amount - BO */}
            {typeReport.complete && typeReport.data.bo && !typeReport.data.bo.startup && (
              <>
                <Box mt={2}>
                  <strong>BVI:</strong>
                </Box>
                <TableRow>
                  <TableCell id="col_last_1" className={classes.paddingZeroLeft}>
                    <Typography variant="body2">
                      <p className={classes.finalHeadtCell}>{getText("pages.report.select.bundleSigned")}</p>
                      {typeReport.data.bo.bundles_sum || getText("pages.report.notFound")}
                    </Typography>
                  </TableCell>
                  <TableCell id="col_last_2" className={classes.paddingZeroLeft}>
                    <Typography variant="body2">
                      <p className={classes.finalHeadtCell}>{getText("pages.report.select.paymentReceived")}</p>
                      {typeReport.data.bo.payments_received_sum || getText("pages.report.notFound")}
                    </Typography>
                  </TableCell>
                  <TableCell id="col_last_3" className={classes.paddingZeroLeft}>
                    <Typography variant="body2">
                      <p className={classes.finalHeadtCell}>{getText("pages.report.select.waitingSum")}</p>
                      {typeReport.data.bo.waiting_sum || getText("pages.report.notFound")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
});

// StartUp little table
const CollapseStartupTable = (round) => {
  const [open, setOpen] = useState(false);
  const getText = useLanguage();
  const roundObj = { ...round.round };
  return (
    <>
      <Box sx={{ paddingBottom: 12 }}>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell>{getText("pages.report.select.rounds")}</TableCell>
              <TableCell>{getText("pages.report.selectRound.start_date")}</TableCell>
              <TableCell>{getText("pages.report.selectRound.finish_date")}</TableCell>
              <TableCell>{getText("pages.report.selectRound.pre_money_evaluation")}</TableCell>
              <TableCell>{getText("pages.report.selectRound.post_money_evaluation")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell id="col_1">
                <Typography variant="body2">{roundObj.round || getText("pages.report.notFound")}</Typography>
              </TableCell>
              <TableCell id="col_2">
                <Typography variant="body2">{roundObj.round_start_date || getText("pages.report.notFound")}</Typography>
              </TableCell>
              <TableCell id="col_3">
                <Typography variant="body2">{roundObj.round_finish_date || getText("pages.report.notFound")}</Typography>
              </TableCell>
              <TableCell id="col_4">
                <Typography variant="body2">{roundObj.pre_money_evaluation || getText("pages.report.notFound")}</Typography>
              </TableCell>
              <TableCell id="col_5">
                <Typography variant="body2">{roundObj.post_money_evaluation || getText("pages.report.notFound")}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
