import React, { useEffect, useMemo } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { NotificationContainer } from "react-notifications";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import YMInitializer from "react-yandex-metrika/lib/init";
// import Sumsub from "./pages/Settings/Sumsub";
import { ApplicantSubmit } from "./pages/Settings/Cabinet/Sumsub/ApplicantSubmit";
import Cabinet from "./pages/Settings/Cabinet/Cabinet";
import Accounts from "./pages/Settings/Accounts";
import Documents from "./pages/Settings/Document/Documents";
import VideoLibrary from "./pages/VideoLibrary/VideoLibrary";
import Investments from "./pages/Portfolio/Investments";
import Secondary from "./pages/Secondary/Secondary";
import Report from "./pages/Report/Report";
import Portfolio from "./pages/Portfolio/Portfolio";
import "moment/locale/ru";
import { useInfo, useNotifications, useProfile, useUSProfile } from "./api/api";
import Password from "./pages/Settings/Password";
import SignUp from "./pages/SignUp/SignUp";
import Profile from "./pages/Settings/Profile";
import useLanguage from "./languages";
import Navigation from "./components/Navigation/Navigation";
import ToolbarRoute from "./components/ToolbarRoute";
import LoginBase from "./pages/LoginBase";
import { StoreProvider, useStore } from "./store";
import theme from "./theme";
import Menu from "./pages/Menu";
import urls, { useAsyncEndpoint } from "./urls";
import Kinescope from "./pages/Kinescope/Kinescope";
import PDFreport from "./pages/PDFReport/PDFReport";
import News from "./pages/News/News";
import { REACT_APP_API_URL } from "./constants/selectConstants";

YMInitializer([80100502], { webvisor: true }, "2");

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      // background: "#FFFFFF",
      display: "block",
      padding: "0 20px",
    },
    display: "flex",
    justifyContent: "center",
    paddingRight: 0,
    paddingLeft: 260,
  },
  flagsLoading: {
    position: "absolute",
    zIndex: -1,
    pointerEvents: "none",
  },
}));

const useRounds = () =>
  useAsyncEndpoint((id) => ({
    url: urls.rounds(id),
    method: "GET",
  }));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Base = observer(({ location, history }) => {
  const classes = useStyles();
  const store = useStore();
  const query = useQuery();
  const getText = useLanguage();
  const [profileData, fetchProfile] = useProfile();
  const [usProfileData, fetchUsProfile] = useUSProfile();
  const [authInfo, fetchAuthInfo] = useInfo();
  const [notifications, fetchNotifications] = useNotifications();
  const [rounds, fetchRounds] = useRounds();

  // eslint-disable-next-line no-sequences
  useEffect(() => (fetchProfile(), fetchAuthInfo(), fetchNotifications()), []);

  useEffect(() => {
    if (profileData.complete && !profileData.error) {
      const { data } = profileData;
      store.setProfile(data);
      store.setAlertOnDeals(data.alert_on_deals);
      store.setAlertOnNewRounds(data.alert_on_new_rounds);
      store.setAlertOnOther(data.alert_on_other);

      if (!store.language) store.setLang(profileData.data.interface_language);

      const fetchUsTokens = async () => {
        const usTokens = await fetch(
          `${REACT_APP_API_URL}/api/v1/investors/request_investor_keypair/`, {
          method: "POST",
          body: JSON.stringify({
            investor_id: data.id,
            email: data.email
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => res.json())

        if (usTokens) {
          localStorage.setItem('us_access', usTokens.access)
          localStorage.setItem('us_refresh', usTokens.refresh)
        }

        fetchUsProfile()
      }

      fetchUsTokens()
    }
    if (authInfo) {
      store.setProfile({
        ...store.profile,
        isExtraAccount: authInfo.data && authInfo.data.is_extra_account,
      });
    }
  }, [profileData, authInfo]);

  useEffect(() => {
    store.setProfileUS(
      usProfileData.data
    )
  }, [usProfileData])

  useEffect(() => {
    fetchRounds("active");
  }, []);

  const getCaption = (reportType) => {
    // if (rounds.data.length) {
    //   const result = rounds.data;
    //   if (result.length) {
    //     return result[0].startup.name;
    //   }
    //   return "";
    // }
    // return "";
    const REPORT_MAPPING = {
      investor: 'Investment portfolio summary',
      ahs: 'Asset holding statement',
      transactional: 'Operations report'
    }

    return REPORT_MAPPING[reportType]
  };
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Container maxWidth="xl" className={classes.root}>
        <Navigation location={location} history={history} />
        <Switch>
          {!store.token.access ? <Redirect to={`/login?${location.pathname}${location.search}`} /> : ""}
          <Route
            path="/settings"
            render={(props) => (
              <ToolbarRoute
                {...props}
                caption={getText("pages.settings.caption")}
                defaultPath="/settings/profile"
                routes={[
                  {
                    key: "profile",
                    label: getText("pages.settings.links.profile"),
                    onlyMain: true,
                    path: "/settings/profile",
                    component: Cabinet,
                    profileCompleted: profileData.is_kyc_checked && profileData.is_accredited && profileData.is_member
                  },
                  {
                    key: "cabinet",
                    label: getText("pages.settings.links.editingInformation"),
                    onlyMain: true,
                    path: "/settings/cabinet",
                    component: Profile,
                  },
                  {
                    key: "password",
                    label: getText("pages.settings.links.password"),
                    onlyMain: true,
                    path: "/settings/password",
                    component: Password,
                  },
                  {
                    key: "accounts",
                    label: getText("pages.settings.links.accounts"),
                    path: "/settings/accounts",
                    component: Accounts,
                  },
                  {
                    key: "documents",
                    label: getText("pages.settings.links.documents"),
                    path: "/settings/documents",
                    component: Documents,
                  },
                ]}
              />
            )}
          />
          <Route
            path="/portfolio"
            render={(props) => (
              <ToolbarRoute
                {...props}
                caption={getText("pages.portfolio.caption")}
                defaultPath="/portfolio/volume"
                noPages
                routes={[
                  {
                    key: "volume",
                    label: getText("pages.portfolio.links.volume"),
                    path: "/portfolio/volume",
                    component: Portfolio,
                  }
                ]}
              />
            )}
          />
          <Route
            path="/investments"
            render={(props) => (
              <ToolbarRoute
                {...props}
                caption={getText("pages.investments.caption")}
                defaultPath="/investments/active"
                routes={[
                  {
                    key: "active",
                    label: getText("pages.investments.links.active"),
                    path: "/investments/active",
                    component: Investments,
                  },
                  {
                    key: "closed",
                    label: getText("pages.investments.links.closed"),
                    path: "/investments/closed",
                    component: () => <Investments closed {...props} />,
                  },
                ]}
              />
            )}
          />
          <Route
            path="/ventureTheory"
            render={(props) => (
              <ToolbarRoute
                {...props}
                caption={getText("videoLibrary")}
                defaultPath="/ventureTheory"
                noPages
                routes={[
                  {
                    key: "active",
                    path: "/ventureTheory",
                    component: VideoLibrary,
                  },
                ]}
              />
            )}
          />
          <Route
            path="/report"
            render={(props) => (
              <ToolbarRoute
                {...props}
                caption={getText("pages.report.caption")}
                defaultPath="/report/active"
                routes={[
                  {
                    key: "active",
                    label: getText("pages.report.caption"),
                    path: "/report/active",
                    component: Report,
                  },
                ]}
              />
            )}
          />
          <Route
            path="/kinescope"
            render={(props) => (
              <ToolbarRoute
                {...props}
                caption={getCaption()}
                defaultPath="/kinescope"
                routes={[
                  {
                    key: "active",
                    path: "/kinescope",
                    component: () => <Kinescope videoId={query.get("videoId")} startUpName={getCaption()} />,
                  },
                ]}
              />
            )}
          />
          <Route
            path="/news"
            render={(props) => (
              <ToolbarRoute
                {...props}
                caption={getText("news")}
                defaultPath="/news?page=1"
                routes={[
                  {
                    key: "news",
                    path: "/news",
                    component: () => <News
                      page={query.get("page") || 1}
                      newsType={query.get("news_type") || "all"}
                      startDate={query.get("start_date") || ""}
                      endDate={query.get("end_date")}
                      startup={query.get("startup") || "showAll"}
                    />,
                  },
                ]}
              />
            )}
          />
          <Route
            path="/pdfReport"
            render={(props) => (
              <ToolbarRoute
                {...props}
                caption={getCaption(query.get("reportType"))}
                defaultPath="/pdfReport"
                routes={[
                  {
                    key: "pdfReport",
                    path: "/pdfReport",
                    component: () => <PDFreport instance={query.get("instance")} reportType={query.get("reportType")} endDate={query.get("endDate")} startDate={query.get("startDate")} split={query.get("split")} />,                  },
                ]}
              />
            )}
          />
          <Route
            path="/thank-you"
            render={(props) => (
              <ToolbarRoute
                {...props}
                caption={getCaption()}
                defaultPath="/thank-you"
                routes={[
                  {
                    key: "thank-you",
                    path: "/thank-you",
                    component: ApplicantSubmit,
                  },
                ]}
              />
            )}
          />

          <Route
              path="/secondary"
              render={(props) => (
                <ToolbarRoute
                  {...props}
                  caption={getText("pages.secondary.caption")}
                  defaultPath="/secondary/sale"
                  routes={[
                    {
                      key: "sale",
                      label: getText("pages.secondary.links.sale"),
                      path: "/secondary/sale",
                      component: () => <Secondary {...props} />,
                    },
                    {
                      key: "buy",
                      label: getText("pages.secondary.links.buy"),
                      path: "/secondary/buy",
                      component: () => <Secondary purchase {...props} />,
                    },
                    {
                      key: "applications",
                      label: getText("pages.secondary.links.applications"),
                      path: "/secondary/applications",
                      component: () => <Secondary isApplications {...props} />,
                    },
                  ]}
                />
              )}
            />
          {/* <Route path="/notifications" render={props =>
                        <ToolbarRoute {...props}
                            caption={getText('pages.notifications.caption')}
                            noPages
                            routes={[{
                                path: '/notifications',
                                component: () => <Notifications history={history}/>
                            }]}
                        />}
                    /> */}
          <Redirect to="/portfolio" />
        </Switch>
      </Container>
    </MuiPickersUtilsProvider>
  );
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NotificationContainer />
      <StoreProvider>
        <Router>
          <Switch key="baseSwitch">
            <Route key="menu" path="/menu" component={Menu} />
            <Route path="/sign-up" key="sign-up" component={SignUp} />
            <Route path="/login" key="login" component={LoginBase} />
            <Route path="/" key="root" component={Base} />
          </Switch>
        </Router>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
