import React, { useRef, useEffect } from "react";
import { Typography, Box, Tabs, Tab, Toolbar, useMediaQuery, Divider, Button, Hidden, LinearProgress } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/styles";
import { Route, Switch, Redirect } from "react-router-dom";
import { withProps } from "recompose";
import posed from "react-pose";
import { observer } from "mobx-react-lite";
import useLanguage from "../languages/index";
import { useStore } from "../store";

const ToolbarPages = withStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      marginLeft: "auto",
    },
  },
  scroller: {
    [theme.breakpoints.up("sm")]: {
      height: 85,
      display: "flex",
    },
    "@media (min-width: 750px) and (max-width: 960px)": {
      height: 64,
      display: "flex",
    },
    [theme.breakpoints.down("md")]: {
      overflowX: "auto !important",
      maxWidth: "calc(100vw - 40px)"
    }
  },
  indicator: {
    bottom: -1,
    zIndex: 1,
  },
  flexContainer: { justifyContent: "space-between" },
}))(Tabs);

const ToolbarTab = withProps({ disableRipple: true })(
  withStyles((theme) => ({
    root: {
      padding: 0,
      minWidth: "auto",
      [theme.breakpoints.up("sm")]: { margin: "0 30.5px" },
      [theme.breakpoints.down("xs")]: { padding: "14px 0 17px" },
    },
    wrapper: {
      textTransform: "none",
      justifyContent: "flex-start",
      "& > *:first-child": {
        marginBottom: "0 !important",
        marginRight: 15,
      },
      [theme.breakpoints.down("md")]: { padding: "0 10px" },
    },
    textColorPrimary: { color: theme.palette.text.primary },
  }))(Tab)
);

const ToolbarContainer = withStyles((theme) => ({
  root: { marginTop: 97, width: "100%" },
}))(Box);

const NavigationToolbar = withStyles((theme) => ({
  regular: {
    position: "fixed",
    left: 0,
    top: 96,
    right: 0,
    zIndex: 3,
    transition: "box-shadow .2s",

    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      padding: "0 20px",
      minHeight: 60,
      maxHeight: 90,
      background: theme.palette.background.paper,
      top: 60,
    },

    "@media (min-width: 750px) and (max-width: 960px)": {
      flexWrap: "no-wrap",
    },

    [theme.breakpoints.up("md")]: {
      top: 0,
      left: 260,
      background: theme.palette.background.default,
    },
  },
}))(Toolbar);

const ToolbarDivider = withStyles((theme) => ({
  root: {
    left: 0,
    right: 0,
    position: "absolute",
    bottom: 0,
  },
}))(Divider);

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 56,
    },
  },
  progress: {
    width: "100vw",
    position: "absolute",
    bottom: 0,
    left: 0,
    height: 1,
    zIndex: 1,
    "@media (min-width: 750px) and (max-width: 960px)": {
      left: "-165px"
    }
  },
  navToolbar: {
    [theme.breakpoints.down("sm")]: {
      top: "60px",
      height: "90px !important",
    },
    "@media (min-width: 750px) and (max-width: 960px)": {
      height: "65px !important",
      transform: "translateX(165px)",
      zIndex: 1199,
      maxWidth: "calc(100vw - 250px)",
      margin: 0,
      padding: 0,
      top: 0,
    }
  },
  navToolbarPages: {
    "@media (min-width: 750px) and (max-width: 960px)": {
      maxWidth: "40vw"
    }
  }
}));

const RouteContainer = posed.div({
  enter: { opacity: 1, scaleX: 1, staggerChildren: 100, delayChildren: 100 },
  exit: { opacity: 0, scaleX: 0.9, transition: { duration: 0 } },
});

export default observer(({ location, history, routes, caption, defaultPath, noPages }) => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:600px)");
  const getText = useLanguage();
  const store = useStore();

  const toolbarRef = useRef();

  const handleScroll = () => {
    if (window.innerWidth < 950) {
      return
    }

    if (window.scrollY > 20 && toolbarRef.current) {
      toolbarRef.current.style.boxShadow = "rgba(57, 110, 190, 0.08) 0px 8px 24px";
    } else {
      toolbarRef.current.style.boxShadow = "none";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const logout = () => {
    store.clearToken();
  };

  return (
    <ToolbarContainer>
      <NavigationToolbar ref={toolbarRef} disableGutters className={classes.navToolbar}>
        <Typography className={classes.title} variant="h5">
          <strong>{caption}</strong>
        </Typography>

        {!noPages && (
          <ToolbarPages
            value={location.pathname}
            onChange={(event, value) => {
              window.scrollTo(0, 0);
              history.push(value);
            }}
            indicatorColor="primary"
            textColor="primary"
            className={classes.navToolbarPages}
          >
            {routes.map((route) => (
              <ToolbarTab
                disabled={route.onlyMain && store.profile.isExtraAccount}
                key={route.key}
                value={route.path}
                label={route.label}
              />
            ))}
          </ToolbarPages>
        )}

        {store.loading && <LinearProgress className={classes.progress} />}

        <Hidden smDown>
          <Box ml={noPages ? "auto" : 3.25} mr={7}>
            <Button size="medium" variant="contained" color="default" className={classes.button} onClick={logout}>
              {getText("logout")}
            </Button>
          </Box>
        </Hidden>

        <Hidden mdDown>
          <ToolbarDivider />
        </Hidden>
      </NavigationToolbar>
      <Box position="relative" mt={desktop ? 5 : 25} mb={desktop ? 20 : 0}>
        <Switch>
          {routes.map((route) => (
            <Route path={route.path} key={route.key} component={route.component} />
          ))}
          {defaultPath && <Redirect to={defaultPath} />}
        </Switch>
      </Box>
    </ToolbarContainer>
  );
});
