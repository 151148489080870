import React, { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Tooltip,
  FormLabel,
  Hidden,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Alert } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/styles";
import { NotificationManager } from "react-notifications";
import MultiSelect from "../../../components/MultiSelect";
import DeleteDialog from "../../../components/DeleteDialog";
import { AddIcon } from "../../../components/Icons";
import PopupPaper from "../../../components/PopupPaper";
import useLanguage from "../../../languages";
import TextInput from "../../../components/TextInput";
import { Autocomplete } from "../../../components/Autocomplete";
import { SELECTS_OPTIONS } from "./ProfileAutocomplete";
import { SELECTS_OPTIONS_CABINET } from "../Cabinet/CabinetAutoComplete";
import { useStore } from "../../../store";
import {
  handleChangeCompanyType,
  handleChangeCompanyAutoCompleteType,
  handleChangeCompanyTypeDate,
  REACT_APP_API_URL,
  handleChangeCompanySelectType,
  handleChangeCompanyCheckedType,
  handleChangeRadioCompanyType,
} from "../../../constants/selectConstants";
import { AutocompleteMaterial } from "../../../components/AutocompleteMaterial";
import { PaperButton, Hover, HoverItem } from "../style";

const Helper = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    height: 16,
    width: 16,
    borderColor: "rgba(126, 159, 193, .5)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    padding: 0,
    color: theme.palette.text.secondary,
    background: "white !important",
  },
  label: {
    fontSize: 10,
    lineHeight: 1,
  },
}))(Button);

async function createUBO(data) {
  const result = await fetch(`https://us.altaclub.vc/api/v1/investors/create_ubo/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("us_access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

async function fillUboIndividualProfile(data) {
  const result = await fetch(`https://us.altaclub.vc/api/v1/investors/fill_ubo_individual_profile/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("us_access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

async function deleteUBO(id) {
  const result = await fetch(`https://us.altaclub.vc/api/v1/investors/remove_ubo/${id}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("us_access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

const useStyles = makeStyles((theme) => ({
  input: { marginTop: 28 },
  input_small: {
    maxWidth: 200,
  },
  button: {
    width: 190,
    padding: "12px 0",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center",
    justifyContent: "center",
  },
  body: {
    fontSize: theme.typography.pxToRem(12),
  },
  under: {
    textDecorationLine: "underline",
  },
  select: { marginTop: 28 },
  checkboxTitle: {
    textAlign: "justify",
    fontSize: "13px",
  },
  addАccount: {
    marginTop: 16,
  },
  addАccount_btn: {
    display: "flex",
    alignItems: "center",
  },
  new_beneficiary: {
    margin: "16px 0",
  },
  bottomLine: {
    border: "1px solid #DBE5EA",
    margin: "32px 0 24px",
  },
  new_beneficiary_cabinet: {
    boxShadow: "none",
    borderRadius: 0,
    marginTop: 10,
  },
  new_beneficiary_head: {
    marginBottom: 25,
    marginTop: 25,
  },
  new_beneficiary_head_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  datePickerLabel: {
    fontSize: 14,
    color: "#7E9FC1",
    display: "block",
  },
  pickerContainerElement: {
    width: "100%",
    "& > div": {
      border: "none",
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
  labelInput: {
    "text-align": "justify",
    display: "flex",
  },
}));

export default observer(
  ({ handleChange, profile, profileUBO, fetchUBO, cabinet, saveCompanyType, handleChangeDate, setProfileUBO, isValidation }) => {
    const getText = useLanguage();
    const classes = useStyles();
    const store = useStore();

    const [errorDocsKys, setErrorDocsKys] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState();
    const [deleteIndex, setDeleteIndex] = useState();
    const [openDelete, openDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState();

    const [openSecretary, setOpenSecretary] = useState(false);
    const handleOpenSecretary = () => setOpenSecretary(!openSecretary);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const drawSignature = async (index, signatureText, uboId) => {
      if (!signatureText) return

      const canvas = document.querySelector(`#ubo-signature-${index}`);
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.textAlign = "center";
      ctx.textBaseline = "center";
      ctx.font = "48px Corinthia";
      ctx.fillText(signatureText || "", canvas.width / 2, canvas.height / 2);

      function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(",");
        const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i += 1) ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
      }

      const image = new Image();
      image.src = canvas.toDataURL();
      const formData = new FormData();
      const file = DataURIToBlob(canvas.toDataURL());
      formData.append("signature_photo", file, "photo.jpg");

      await fetch(`https://us.altaclub.vc/api/v1/investors/update_ubo/${uboId}/`, {
        method: "PATCH",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_access")}`,
        },
      });
    }

    const drawSignedDate = async (index, signedDate, uboId) => {
      if (!signedDate) return
  
      const canvas = document.querySelector(`#ubo-signed-date-${index}`);
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.textAlign = "center";
      ctx.textBaseline = "center";
      ctx.font = "48px Corinthia";
      ctx.fillText(signedDate || "", canvas.width / 2, canvas.height / 2);

      await fetch(`https://us.altaclub.vc/api/v1/investors/update_ubo/${uboId}/`, {
        method: "PATCH",
        body: JSON.stringify({ profile_signed_date: signedDate }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_access")}`,
          'Content-type': 'application/json'
        },
      })
    }

    const addNewBeneficiary = useCallback(() => {
      try {
        if (profileUBO.length) {
          saveCompanyType("ubo", profileUBO);
        }
      } catch (error) {
        NotificationManager.error("There's an error. Please, check your input data.");
      }

      NotificationManager.info("Beneficiary is being added, please wait...");

      createUBO()
        .then(() => {
          fetchUBO();
        })
        .catch(); // TODO обработать ошибки
    });

    useEffect(() => {
      fetchUBO();
    }, []);

    const deleteAccount = (index, id) => () => {
      setDeleteTitle(getText("tips.removeAccount", index + 1));
      setDeleteId(id);
      setDeleteIndex(index);
      openDeleteDialog(true);
    };

    const handleDelete = useCallback((confirm, id) => {
      console.log('confirm is', confirm)
      try {
        saveCompanyType("ubo", profileUBO);
      } catch (error) {
        NotificationManager.error("There's an error. Please, check your input data.");
      }

      if (confirm) {
        NotificationManager.info("Beneficiary is being removed, please wait...");

        setTimeout(() => {
          deleteUBO(id)
          .then(() => {
            fetchUBO();
          })
          .catch(); // TODO обработать ошибки;
        })
      }
      openDeleteDialog(false);
    });

    const BLOCKED_COUNTRIES = ['RU', 'BY'] // РФ, Белоруссия

    return (
      <div>
        <Box mt={2} />
        {!cabinet && (
          <PopupPaper inline>
            <Box mt={5}>
              <Typography variant="h5">
                <strong>{getText("UBO")}</strong>
              </Typography>
            </Box>
            <TextInput
              type="text"
              multiline
              hideMultiline
              toolTip="sourceWealth"
              className={classes.input}
              value={profile?.source_of_wealth}
              onChange={handleChange("source_of_wealth")}
              caption={getText("source_of_wealth")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.postal_address}
              onChange={handleChange("postal_address")}
              caption={getText("postal_address")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.political_refugee_text}
              onChange={handleChange("political_refugee_text")}
              caption={getText("political_refugee")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.convictions_text}
              onChange={handleChange("convictions_text")}
              caption={getText("convictions")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.under_investigation_text}
              onChange={handleChange("under_investigation_text")}
              caption={getText("under_investigation")}
            />
            <Box className={classes.select}>
              <Autocomplete
                notflag
                options={SELECTS_OPTIONS.power_of_attorney_select.map((el) => ({
                  label: getText(`${el.label}`),
                  value: el.value,
                }))}
                caption={getText("power_of_attorney")}
                value={profile?.power_of_attorney}
                onChange={handleChange("power_of_attorney")}
              />
              {profile.power_of_attorney && (
                <>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={profile?.attorney_full_name}
                    onChange={handleChange("attorney_full_name")}
                    caption={getText("attorney_full_name")}
                  />
                  <TextInput
                    type="number"
                    className={classes.input}
                    value={profile?.attorney_phone}
                    onChange={handleChange("attorney_phone")}
                    caption={getText("attorney_phone")}
                  />
                  <TextInput
                    type="email"
                    className={classes.input}
                    value={profile?.attorney_email}
                    onChange={handleChange("attorney_email")}
                    caption={getText("attorney_email")}
                  />
                </>
              )}
            </Box>
            <Box className={classes.bottomLine} />
            <div className={classes.input}>{getText("tips.bankImportant")}</div>
            <div className={classes.input}>{getText("tips.attention")}</div>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.bank_name}
              onChange={handleChange("bank_name")}
              caption={getText("bank_name")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.bank_address}
              onChange={handleChange("bank_address")}
              caption={getText("bank_address")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.iban}
              onChange={handleChange("iban")}
              caption={getText("iban")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.swift}
              onChange={handleChange("swift")}
              caption={getText("swift")}
            />
            <Box className={classes.bottomLine} />
            <div className={classes.input}>{getText("tips.cashDistributions")}</div>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_account}
              onChange={handleChange("distributions_account")}
              caption={getText("distributions.account")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_address}
              onChange={handleChange("distributions_address")}
              caption={getText("distributions.address")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_bank_name}
              onChange={handleChange("distributions_bank_name")}
              caption={getText("distributions.bankName")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_bank_address}
              onChange={handleChange("distributions_bank_address")}
              caption={getText("distributions.bankAddress")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_swift}
              onChange={handleChange("distributions_swift")}
              caption={getText("distributions.SWIFT")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_account_number}
              onChange={handleChange("distributions_account_number")}
              caption={getText("distributions.acctNum")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_instructions}
              onChange={handleChange("distributions_instructions")}
              caption={getText("distributions.instructions")}
            />
            <Box mt={2} />
            {errorDocsKys && (
              <div className="text-container">
                <div className="text-error">{getText("doc_errors")}</div>
              </div>
            )}
          </PopupPaper>
        )}
        {profileUBO &&
          profileUBO.map((beneficiary, index) => (
            <div key={beneficiary.id}>
              <DeleteDialog onClose={handleDelete} title={deleteTitle} open={openDelete} id={deleteId} />
              <PopupPaper className={cabinet ? classes.new_beneficiary_cabinet : classes.new_beneficiary} inline={!cabinet}>
                <Box ml="auto" className={classes.new_beneficiary_head_box}>
                  <Typography className={classes.new_beneficiary_head} variant="h5">
                    <strong>
                      {getText("beneficiary")} {index + 1}
                    </strong>
                  </Typography>
                  {index > 0 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={deleteAccount(index, beneficiary?.id)}
                    >
                      {getText("delete")}
                    </Button>
                  )}
                </Box>
                {beneficiary.individual_profile_has_error && (
                  <>
                    <Alert severity="warning">{beneficiary.individual_profile_error}!</Alert>
                    <Button
                      type="button"
                      onClick={() => fillUboIndividualProfile({ ubo: beneficiary.id })}
                      fullWidth
                      size="large"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: 28 }}
                    >
                      {getText("fix")}
                    </Button>
                  </>
                )}
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.last_name_en}
                    onChange={handleChangeCompanyType("last_name_en", index, profileUBO)}
                    caption={getText("pages.cabinet.memberInformation.surname")}
                    required InputProps={{required: true}}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.first_name_en}
                    onChange={handleChangeCompanyType("first_name_en", index, profileUBO)}
                    caption={getText("pages.cabinet.memberInformation.forename")}
                    required InputProps={{required: true}}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.middle_name_en}
                    onChange={handleChangeCompanyType("middle_name_en", index, profileUBO)}
                    caption={getText("pages.cabinet.memberInformation.patronymic")}
                  />
                </Box>
                <Box mt={2}>
                  <AutocompleteMaterial
                    flag
                    options={store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
                    text="citizenship"
                    title="Citizenship"
                    value={beneficiary.citizenship}
                    onChange={handleChangeCompanyAutoCompleteType("citizenship", index, profileUBO, setProfileUBO)}
                    required InputProps={{required: true}}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.passport_number}
                    onChange={handleChangeCompanyType("passport_number", index, profileUBO)}
                    caption={getText("pages.cabinet.memberInformation.passportNo")}
                    required InputProps={{required: true}}
                  />
                </Box>
                <Box mt={2}>
                  <span className={classes.datePickerLabel}>{getText("pages.cabinet.memberInformation.expiryDate")}*</span>
                  <DatePicker
                    className={classes.pickerContainerElement}
                    onChange={handleChangeCompanyTypeDate("date_of_passport_issue", index, profileUBO, setProfileUBO)}
                    format="DD/MM/YYYY"
                    animateYearScrolling
                    value={beneficiary.date_of_passport_issue}
                  />
                </Box>
                <Box mt={2}>
                  <AutocompleteMaterial
                    flag
                    options={
                      store.citizenships_en
                        .map((code) => ({ label: getText(`countries.${code}`), value: code }))
                        .filter((item) => !BLOCKED_COUNTRIES.includes(item.value))
                    }
                    text="residence"
                    title="Residence country"
                    value={beneficiary?.residence}
                    onChange={handleChangeCompanyAutoCompleteType("residence", index, profileUBO, setProfileUBO)}
                    required InputProps={{required: true}}
                  />
                </Box>
                <Box className={classes.bottomLine} />

                <Box mt={2}>
                  <FormLabel className={classes.input} component="legend">
                    <Typography className={classes.labelInput}>
                      {getText("pages.cabinet.usPerson.usPersonTitle")}
                    </Typography>
                  </FormLabel>

                  <RadioGroup
                    aria-label="is_us_person"
                    className={classes.radioGroup}
                    value={beneficiary?.is_us_person}
                    dataattribute="is_us_person"
                    onChange={handleChangeCompanyAutoCompleteType("is_us_person", index, profileUBO, setProfileUBO)}
                    required InputProps={{required: true}}
                  >
                    <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>

                  <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Tooltip placement="right" title={getText("pages.cabinet.tooltips.usPerson")}>
                      <Box mt={2}>{getText("pages.cabinet.tooltips.titleUsPerson")}</Box>
                    </Tooltip>
                    <Tooltip placement="right" title={getText("pages.cabinet.tooltips.notUsPerson")}>
                      <Box mt={2}>{getText("pages.cabinet.tooltips.titleNonUsperson")}</Box>
                    </Tooltip>
                  </Box>
                </Box>

                {beneficiary.is_us_person === "YES" && (
                  <>
                    <Box className={classes.bottomLine} />
                    <Box mt={2}>
                      <AutocompleteMaterial
                        options={SELECTS_OPTIONS_CABINET.is_us_federal_taxes.map((el) => ({
                          label: getText(`${el.label}`),
                          value: el.value,
                        }))}
                        text="is_us_federal_taxes"
                        title="pages.cabinet.usIncomeTax.usIncomeTaxTitle"
                        value={beneficiary?.is_us_federal_taxes}
                        onChange={handleChangeCompanyAutoCompleteType("is_us_federal_taxes", index, profileUBO, setProfileUBO)}
                        required InputProps={{required: true}}
                      />
                    </Box>
                    <Box mt={2}>
                      <TextInput
                        type="text"
                        className={classes.input}
                        value={beneficiary?.social_security_number}
                        onChange={handleChangeCompanyType("social_security_number", index, profileUBO)}
                        caption={getText("pages.cabinet.usIncomeTax.securityNumber")}
                        required InputProps={{required: true}}
                      />
                    </Box>
                    <Box mt={2}>
                      <TextInput
                        type="text"
                        className={classes.input}
                        value={beneficiary?.green_card_number}
                        onChange={handleChangeCompanyType("green_card_number", index, profileUBO)}
                        caption={getText("pages.cabinet.usIncomeTax.greenCard")}
                      />
                    </Box>
                  </>
                )}
                {beneficiary.is_us_person === "NO" && (
                  <Box mt={2}>
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.tingin}
                      onChange={handleChangeCompanyType("tingin", index, profileUBO)}
                      caption={getText("pages.cabinet.tingin")}
                      required InputProps={{required: true}}
                    />
                  </Box>
                )}
                <Box className={classes.bottomLine} />
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.residence_address}
                    onChange={handleChangeCompanyType("residence_address", index, profileUBO)}
                    caption={getText("pages.cabinet.residenceAddress")}
                    required InputProps={{required: true}}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.mailing_address}
                    onChange={handleChangeCompanyType("mailing_address", index, profileUBO)}
                    caption={getText("pages.cabinet.mailingAddress")}
                  />
                </Box>
                <Box mt={2}>
                  <FormControlLabel
                    className={classes.checkboxTitle}
                    label={<p className={classes.checkboxTitle}>{getText("pages.cabinet.electronicSigning")}*</p>}
                    control={
                      <Checkbox
                        checked={beneficiary.agree_to_electronic_signing === "YES"}
                        onChange={handleChangeCompanyCheckedType("agree_to_electronic_signing", index, profileUBO, setProfileUBO)}
                        color="primary"
                        className={classes.checkbox}
                        required InputProps={{required: true}}
                      />
                    }
                  />
                </Box>
                <Box className={classes.bottomLine} />
                <Typography variant="h5">Bank information</Typography>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.bank_name}
                    onChange={handleChangeCompanyType("bank_name", index, profileUBO)}
                    caption={getText("pages.cabinet.bankName")}
                  />
                </Box>
                <Box mt={2}>
                  <AutocompleteMaterial
                    flag
                    options={store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
                    text="bank_country"
                    title={getText("pages.cabinet.bankCountry")}
                    value={beneficiary?.bank_country}
                    onChange={handleChangeCompanyAutoCompleteType("bank_country", index, profileUBO, setProfileUBO)}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.swift}
                    onChange={handleChangeCompanyType("swift", index, profileUBO)}
                    caption={getText("pages.cabinet.swiftNo")}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.account_number}
                    onChange={handleChangeCompanyType("account_number", index, profileUBO)}
                    caption={getText("pages.cabinet.acctNo")}
                  />
                </Box>
                <Box className={classes.bottomLine} />
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={beneficiary?.total_estimated_net_wealth}
                    onChange={handleChangeCompanyType("total_estimated_net_wealth", index, profileUBO)}
                    caption={getText("pages.cabinet.estimatedIncluding.totalEstimatedWealth")}
                    required InputProps={{required: true}}
                  />
                </Box>
                <Box className={classes.bottomLine} />

                <Box mt={2}>

                  <Box id="individual_interest_ownership" mt={2}>
                    <AutocompleteMaterial
                      options={SELECTS_OPTIONS_CABINET.interestOwnershipTypes.map((el) => ({
                        label: getText(`${el.label}`),
                        value: el.value,
                      }))}
                      text="individual_interest_ownership"
                      title="pages.cabinet.interestOwnershipTypes.title"
                      value={beneficiary?.individual_interest_ownership}
                      onChange={handleChangeCompanyAutoCompleteType("individual_interest_ownership", index, profileUBO, setProfileUBO)}
                    />
                    {beneficiary.individual_interest_ownership === "OT" && (
                      <TextInput
                        type="text"
                        className={classes.input}
                        value={beneficiary?.individual_interest_ownership_other}
                        onChange={handleChangeCompanyType("individual_interest_ownership_other", index, profileUBO)}
                        caption={getText("pages.cabinet.interestOwnershipTypes.titleOther")}
                      />
                    )}
                  </Box>
                </Box>

                <Box className={classes.bottomLine} />
                <Box mt={2}>
                  <FormLabel className={classes.input} component="legend" flex>
                    <Typography className={classes.labelInput}>
                      {getText("pages.cabinet.seniorForeignPolitical")}*
                      <Hidden xsDown>
                        <Tooltip placement="right" title={getText("pages.cabinet.seniorForeignPoliticalTooltip")}>
                          <Box ml={1} mt={-0.2}>
                            <Helper disableRipple>?</Helper>
                          </Box>
                        </Tooltip>
                      </Hidden>
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-label="is_senior_foreign_political_figure"
                    value={beneficiary.is_senior_foreign_political_figure}
                    className={classes.radioGroup}
                    onChange={handleChangeRadioCompanyType(
                      "is_senior_foreign_political_figure",
                      index,
                      profileUBO,
                      setProfileUBO
                    )}
                  >
                    <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </Box>
                <Box className={classes.bottomLine} />
                <Box mt={2}>
                  <FormLabel className={classes.input} component="legend">
                    <Typography className={classes.labelInput}>{getText("pages.cabinet.criminalVictions")}*</Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-label="convictions"
                    value={beneficiary.convictions}
                    className={classes.radioGroup}
                    onChange={handleChangeRadioCompanyType("convictions", index, profileUBO, setProfileUBO)}
                  >
                    <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </Box>
                {beneficiary.convictions === "YES" && (
                  <Box mt={2}>
                    <TextInput
                      type="text"
                      caption=" "
                      className={classes.input}
                      value={beneficiary?.criminal_descriptions}
                      onChange={handleChangeCompanyType("criminal_descriptions", index, profileUBO)}
                      required InputProps={{required: true}}
                    />
                  </Box>
                )}
                <Box className={classes.bottomLine} />
                <Box mt={2}>
                  <FormLabel className={classes.input} component="legend">
                    <Typography className={classes.labelInput}>{getText("pages.cabinet.underInvestigation")}*</Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-label="under_investigation"
                    value={beneficiary?.under_investigation}
                    className={classes.radioGroup}
                    onChange={handleChangeRadioCompanyType("under_investigation", index, profileUBO, setProfileUBO)}
                  >
                    <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </Box>
                {beneficiary.under_investigation === "YES" && (
                  <Box mt={2}>
                    <TextInput
                      type="text"
                      caption=" "
                      className={classes.input}
                      value={beneficiary?.under_investigation_text}
                      onChange={handleChangeCompanyType("under_investigation_text", index, profileUBO)}
                      required InputProps={{required: true}}
                    />
                  </Box>
                )}
                <Box className={classes.bottomLine} />
                <Box mt={2}>
                  <MultiSelect
                    select={SELECTS_OPTIONS_CABINET}
                    moreText
                    handleChangeSelect={handleChangeCompanySelectType(
                      "accredited_investor_representation_points",
                      index,
                      profileUBO,
                      setProfileUBO
                    )}
                    profile={beneficiary.accredited_investor_representation_points}
                    type="accredited_investor_representation_points"
                    required InputProps={{required: true}}
                  />
                </Box>
                {beneficiary?.accredited_investor_representation_points &&
                  beneficiary?.accredited_investor_representation_points.includes("OTHER_ENTITIES") && (
                    <Box mt={2}>
                      <MultiSelect
                        select={SELECTS_OPTIONS_CABINET}
                        moreText
                        handleChangeSelect={handleChangeCompanySelectType(
                          "accredited_investor_representation_points_other",
                          index,
                          profileUBO,
                          setProfileUBO
                        )}
                        profile={beneficiary.accredited_investor_representation_points_other}
                        type="accredited_investor_representation_points_other"
                        required InputProps={{required: true}}
                      />
                    </Box>
                  )}
                <Box className={classes.bottomLine} />
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Box>
                    <canvas width="325" height="75" id={`ubo-signature-${index}`} />

                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.signature_text}
                      onChange={(event) => {
                        handleChangeCompanyType("signature_text", index, profileUBO)(event)
                        drawSignature(index, beneficiary.signature_text, beneficiary.id)
                      }}
                      caption="Signature"
                      required InputProps={{required: true}}
                    />
                  </Box>
                  <Box mt={2.5}>
                    <canvas width="325" height="75" id={`ubo-signed-date-${index}`} />

                    <span className={classes.datePickerLabel}>Date *</span>

                    <DatePicker
                      className={classes.pickerContainerElement}
                      onChange={(event) => {
                        handleChangeCompanyTypeDate("profile_signed_date", index, profileUBO, setProfileUBO)(event)
                        drawSignedDate(index, beneficiary.profile_signed_date, beneficiary.id)
                      }}
                      format="DD/MM/YYYY"
                      animateYearScrolling
                      value={beneficiary.profile_signed_date}
                    />
                  </Box>
                </Box>
                <Box>
                  <p className={classes.fontSmall} style={{maxWidth: "50%"}}>
                    Only the beneficiary of the company can sign the Beneficiary questionnaire.
                  </p>
                </Box>
                {!cabinet && (
                  <>
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.last_name}
                      onChange={handleChangeCompanyType("last_name", index, profileUBO)}
                      caption={getText("beneficiary_surname_cyrillic")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.first_name}
                      onChange={handleChangeCompanyType("first_name", index, profileUBO)}
                      caption={getText("beneficiary_name_cyrillic")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.middle_name}
                      onChange={handleChangeCompanyType("middle_name", index, profileUBO)}
                      caption={getText("beneficiary_middle_name_cyrillic")}
                    />
                    <Box className={classes.bottomLine} />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.middle_name_en}
                      onChange={handleChangeCompanyType("middle_name_en", index, profileUBO)}
                      caption={getText("beneficiary_middle_name_latin")}
                    />
                    <Box className={classes.bottomLine} />
                    <TextInput
                      type="phone"
                      className={classes.input}
                      value={beneficiary?.phone}
                      onChange={handleChangeCompanyType("phone", index, profileUBO)}
                      caption={getText("phone")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.date_of_birth}
                      onChange={handleChange("date_of_birth")}
                      caption={getText("date_of_birth")}
                    />
                    <Box className={classes.bottomLine} />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.cd_beneficiary_address}
                      onChange={handleChangeCompanyType("cd_beneficiary_address", index, profileUBO)}
                      caption={getText("cdBeneficiaryAddress")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.cd_beneficiary_name}
                      onChange={handleChangeCompanyType("cd_beneficiary_name", index, profileUBO)}
                      caption={getText("cdBeneficiaryName")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.cd_further_credit}
                      onChange={handleChangeCompanyType("cd_further_credit", index, profileUBO)}
                      caption={getText("cdFurtherCredit")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.cd_intermediary_bank_name}
                      onChange={handleChangeCompanyType("cd_intermediary_bank_name", index, profileUBO)}
                      caption={getText("cdIntermediaryBankName")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.criminal_descriptions}
                      onChange={handleChangeCompanyType("criminal_descriptions", index, profileUBO)}
                      caption={getText("criminalDescriptions")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.jurisdiction}
                      onChange={handleChangeCompanyType("jurisdiction", index, profileUBO)}
                      caption={getText("jurisdiction")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.paternal_inheritance}
                      onChange={handleChangeCompanyType("paternal_inheritance", index, profileUBO)}
                      caption={getText("paternalInheritance")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.salary}
                      onChange={handleChangeCompanyType("salary", index, profileUBO)}
                      caption={getText("salary")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.sale_of_business}
                      onChange={handleChangeCompanyType("sale_of_business", index, profileUBO)}
                      caption={getText("saleOfBusiness")}
                    />
                    <TextInput
                      type="number"
                      className={classes.input}
                      value={beneficiary?.ownership_percentage}
                      onChange={handleChangeCompanyType("ownership_percentage", index, profileUBO)}
                      caption={getText("ownershipPercentage")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.tingin}
                      onChange={handleChangeCompanyType("tingin", index, profileUBO)}
                      caption={getText("tingin")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.mailing_address}
                      onChange={handleChangeCompanyType("mailing_address", index, profileUBO)}
                      caption={getText("mailingAddress")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.website}
                      onChange={handleChangeCompanyType("website", index, profileUBO)}
                      caption={getText("website")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.occupation_text}
                      onChange={handleChangeCompanyType("occupation_text", index, profileUBO)}
                      caption={getText("occupationText")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.occupation_detail}
                      onChange={handleChangeCompanyType("occupation_detail", index, profileUBO)}
                      caption={getText("occupationMulti.position")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.occupation_full_name}
                      onChange={handleChangeCompanyType("occupation_full_name", index, profileUBO)}
                      caption={getText("occupationMulti.fullName")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.occupation_address}
                      onChange={handleChangeCompanyType("occupation_address", index, profileUBO)}
                      caption={getText("occupationMulti.address")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.occupation_website}
                      onChange={handleChangeCompanyType("occupation_website", index, profileUBO)}
                      caption={getText("occupationMulti.website")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.occupation_activity}
                      onChange={handleChangeCompanyType("occupation_activity", index, profileUBO)}
                      caption={getText("occupationMulti.mainActivity")}
                    />
                    <Box id="occupation_country" mt={3}>
                      <Autocomplete
                        options={
                          store.language === "ru"
                            ? store.citizenships.map((code) => ({ label: getText(`countries.${code}`), value: code }))
                            : store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))
                        }
                        caption={getText("occupationMulti.country")}
                        value={beneficiary?.occupation_country}
                        onChange={handleChangeCompanyType("occupation_country", index, profileUBO)}
                      />
                    </Box>
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.occupation_inn}
                      onChange={handleChangeCompanyType("occupation_inn", index, profileUBO)}
                      caption={getText("occupationMulti.INN")}
                    />
                    <TextInput
                      type="text"
                      className={classes.input}
                      value={beneficiary?.occupation_revenue}
                      onChange={handleChangeCompanyType("occupation_revenue", index, profileUBO)}
                      caption={getText("occupationMulti.revenue")}
                    />
                  </>
                )}
              </PopupPaper>
            </div>
          ))}
        <Box className={classes.addАccount}>
          <Hover className={classes.forms}>
            <PaperButton fullWidth color="secondary" variant="contained" onClick={addNewBeneficiary}>
              <Box className={classes.addАccount_btn}>
                <HoverItem>
                  <Typography color="textSecondary" variant="inherit">
                    <strong>{getText("add_beneficiary")}</strong>
                  </Typography>
                </HoverItem>
                <HoverItem>
                  <Box fontSize={40} lineHeight={0} ml="auto">
                    <AddIcon fontSize="inherit" />
                  </Box>
                </HoverItem>
              </Box>
            </PaperButton>
          </Hover>
        </Box>
      </div>
    );
  }
);
