import React, { useState } from "react";
import { Dialog, DialogTitle, Box, IconButton, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { NotificationManager } from "react-notifications";
import useLanguage from "../languages/index";
import TextInput from "./TextInput";
import PopupPaper from "./PopupPaper";
import { REACT_APP_API_URL } from "../constants/selectConstants";
import urls from '../urls'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  dialog: {
    padding: "40px",
  },
  dialogTitle: {
    padding: 0,
    "& h2": {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "24px",
    },
  },
  input: {
    minHeight: 96,
  },
  button: {
    width: 254,
    padding: "12px 0",
  },
  paper: {
    marginBottom: 8,
    padding: 10,
  },
}));

const initialState = {
  sharesAmount: "",
  numberMin: "",
  numberMax: "",
  errors: {},
};

export default function SecondaryKnowMorePopup({ onClose, open, modalStartup, purchase, archived }) {
  const classes = useStyles();
  const getText = useLanguage();
  const [state, setState] = useState(initialState);

  const onCloseModal = (hasAction = false) => {
    setState(initialState);
    onClose(hasAction);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (JSON.stringify(state) !== JSON.stringify(initialState)) {
      const data = {
        min_deal_amount: state.numberMin || null,
        max_deal_amount: state.numberMin || null,
        shares_amount: state.sharesAmount || null,
        application_type: purchase ? "purchase" : "sale",
        startup: modalStartup.id,
        instance_type: modalStartup.instance
      };
      
      const url = archived ?
        `${REACT_APP_API_URL}${urls.secondaryCreateApplication()}?archived=1` :
        `${REACT_APP_API_URL}${urls.secondaryCreateApplication()}`

      await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      await onCloseModal(true);

      // NotificationManager.success(
      //   `
      //   Thank you!
      //   Your offer is sent for the approval. 
      //   We will update you once it is listed.
      //   `
      // )
    } else {
      NotificationManager.error(getText("errors.emptyFields"));
    }
  };

  const formatMoney = (amount) => {
    if (amount && typeof amount !== 'string') {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
  
    return '0'
  }

  const modalName = () => {
    const isBuy = window.location.href.includes('buy')

    if (!modalStartup) {
      return "Unknown"
    }

    if (isBuy && modalStartup.shares) {
      return `${modalStartup.name} (totally ${modalStartup.shares} shares)`
    }

    return modalStartup.name
  }

  const estimationCaption = () => {
    let caption = getText("pages.secondary.pricePerShare")

    if (modalStartup && modalStartup.pps) {
      caption = `${caption} (current estimation: $${formatMoney(modalStartup.pps)})`
    }

    return caption
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth open={open} onClose={() => onCloseModal()}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography color="primary" variant="inherit">
          {modalName()}
        </Typography>
        <IconButton onClick={() => onCloseModal()} className={classes.closeButton} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box component="form" onSubmit={onSubmit}>
        <Box mt={3}>
          {!purchase && (
            <TextInput
              type="number"
              InputProps={{
                max: modalStartup ? Number(modalStartup.shares) : null
              }}
              value={state.sharesAmount}
              onChange={(event) => setState({ ...state, sharesAmount: event.target.value })}
              caption={`${getText("pages.secondary.sharesAmount")} (${modalStartup ? formatMoney(modalStartup.shares) : 0} avail)`}
            />
          )}
        </Box>
        <Box mt={3} display="flex">
          <TextInput
            type="number"
            value={state.numberMin}
            onChange={(event) => setState({ ...state, numberMin: event.target.value })}
            caption={estimationCaption()}
          />
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Button onClick={() => onCloseModal()} className={classes.button} size="large" color="secondary" variant="contained">
            {getText("pages.secondary.close")}
          </Button>
          <Box mr={2} />
          <Button type="submit" className={classes.button} size="large" color="secondary" variant="contained">
            {purchase ? "Buy" : getText("pages.secondary.sale")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
