import React, { useState } from "react";
import { Dialog, DialogTitle, Box, IconButton, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { NotificationManager } from "react-notifications";
import useLanguage from "../languages/index";
import { REACT_APP_API_URL } from "../constants/selectConstants";
import urls from '../urls'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  dialog: {
    padding: "40px",
  },
  dialogTitle: {
    padding: 0,
    "& h2": {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "24px",
    },
  },
  input: {
    minHeight: 96,
  },
  button: {
    width: 254,
    padding: "12px 0",
  },
  paper: {
    marginBottom: 8,
    padding: 10,
  },
}));

const initialState = {
  sharesAmount: "",
  numberMin: "",
  numberMax: "",
  errors: {},
};

export default function SecondaryCancellationPopup({ onClose, open, modalStartup }) {
  const classes = useStyles();
  const getText = useLanguage();
  const [state, setState] = useState(initialState);

  const onCloseModal = (hasAction = false) => {
    setState(initialState);
    onClose(hasAction);
  };

  const onSubmit = async () => {
    const data = {
      is_cancelled: true,
      instance_type: modalStartup.instance
    };

    await fetch(`${REACT_APP_API_URL}${urls.secondaryCancelApplication(modalStartup.applicationId)}`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });

    await onCloseModal(true);

    // NotificationManager.success(
    //   `
    //   Success!
    //   Your offer has been cancelled.
    //   `
    // )
  };

  const modalName = () => {
    if (!modalStartup) {
      return "Unknown"
    }

    return `Confirm ${modalStartup.name} cancellation`
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth open={open} onClose={() => onCloseModal()}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography color="primary" variant="inherit">
          {modalName()}
        </Typography>
        <IconButton onClick={() => onCloseModal()} className={classes.closeButton} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box component="form" onSubmit={onSubmit}>
        <Box mt={3} display="flex">
            <Typography variant="h6">
                Are you sure?
            </Typography>
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Button onClick={() => onCloseModal()} className={classes.button} size="large" color="secondary" variant="contained">
            {getText("pages.secondary.close")}
          </Button>          
          <Box mr={2} />
          <Button onClick={onSubmit} className={classes.button} size="large" color="secondary" variant="contained">
            Confirm
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
