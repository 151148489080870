import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Box, Typography, TextField, ClickAwayListener, IconButton } from "@material-ui/core";
import KinescopePlayer from "@kinescope/react-kinescope-player";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import { useStore } from "../../store";
import useLanguage from "../../languages";
import { useStyles } from "./styled";

export default observer(({ videoId, startUpName }) => {
  const store = useStore();
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const getText = useLanguage();
  //   const [openInvest, openInvestDialog] = useState(false);
  //   const playerRef = React.createRef();

  //   const handleInvestOpen = () => {
  //     openInvestDialog((prev) => !prev);
  //   };

  //   const handleInvestClose = () => {
  //     openInvestDialog(false);
  //   };

  const investorData = {
    is_member: store.profile.is_member,
    is_kyc_checked: store.profile.is_kyc_checked,
    is_accredited: store.profile.is_accredited,
  };

  const modalWindow = () => {
    let text = "";
    let href = "";
    if (!investorData.is_kyc_checked) {
      text = getText("pages.investments.modalText.kyc_signed");
      href = "/settings/profile";
    }
    if (investorData.is_kyc_checked && !investorData.is_accredited) {
      text = getText("pages.investments.modalText.accredited");
    }
    if (investorData.is_kyc_checked && investorData.is_accredited) {
      text = getText("pages.investments.modalText.accredited");
    }
    if (investorData.is_kyc_checked && investorData.is_accredited) {
      text = getText("pages.investments.modalText.moderate");
    }
    return <ModalText text={text} href={href} />;
  };

  //   const onCallAction = (id, type) => {
  //     handleInvestOpen();
  //   };

  useEffect(() => {
    location.search ? "" : history.push("/portfolio/volume");
  }, [location, history]);
  return (
    <>
      {!investorData.is_member ? (
        <Box mt={2} className={classes.container}>
          {modalWindow()}
        </Box>
      ) : (
        <Box className={classes.videoContainer}>
          <KinescopePlayer
            className={classes.kinescope}
            videoId={videoId}
            frameBorder="0"
            watermarkText={`${store.profile.watermark_text}`}
            watermarkMode="random"
            allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
            allowFullScreen
            // onCallAction={onCallAction}
            // ref={playerRef}
            // actions={[
            //   {
            //     id: "kinescopeButtonCTA",
            //     title: getText("invest"),
            //     type: "cta",
            //     description: getText("wantInvest"),
            //     skipable: true,
            //     trigger: {
            //       /** Процент текущего времени, например: `[0, 100]`. */
            //       percentages: [98, 100],
            //       pause: false,
            //     },
            //   },
            // ]}
          />
          {/* <ClickAwayListener onClickAway={handleInvestClose}>
            <Box className={classes.listener}>
              {openInvest ? (
                <Box className={classes.openListener}>
                  <Typography className={classes.dialogTitle}>
                    {getText("investIn")}
                    <IconButton
                      aria-label="upload picture"
                      component="span"
                      className={classes.closeButton}
                      onClick={handleInvestOpen}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Typography>
                  <Box style={{ padding: 8 }}>
                    <TextField autoFocus id="invest" type="number" fullWidth variant="standard" autoComplete="off" />
                  </Box>
                  <Button className={classes.greenButton} size="large" variant="contained" onClick={handleInvestOpen}>
                    {getText("send")}
                  </Button>
                </Box>
              ) : null}
            </Box>
          </ClickAwayListener> */}
        </Box>
      )}
    </>
  );
});

const ModalText = ({ text, href }) => {
  const classes = useStyles();
  return (
    <div className={classes.ModalMessage}>
      <p className={classes.text}>{text}</p>
      {href && (
        <NavLink component={Button} to={href} className={classes.buttonModal} size="large" color="primary" variant="contained">
          Ok
        </NavLink>
      )}
    </div>
  );
};
