import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import posed, { PoseGroup } from "react-pose";
import { makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import Navigation from "../../components/Navigation/Navigation";
import { useStore } from "../../store/index";
import { langMap } from "../../languages";
import SignUpPrimaryNik from "./Primary/SignUpPrimary";
import SignUpSecondary from "./Secondary/SignUpSecondary";
import { FinishPage } from "./Secondary/FinishPage";

const RouteContainer = posed.div();
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      background: "#FFFFFF",
      display: "block",
    },
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default observer(({ history, location }) => {
  const classes = useStyles();
  const store = useStore();

  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);
    const languageParam = params.get("language");
    langMap.forEach((el) => el.code === languageParam && store.setLang(languageParam));
  }, [location.search]);

  if (store.token.access) history.push("/");

  return (
    <Container className={classes.root}>
      <Navigation key="navigation" login location={location} />
      <PoseGroup>
        <RouteContainer key="signUpTransition">
          <Switch>
            <Route key="signUpFinish" path="/sign-up/finish" component={FinishPage} />
            <Route key="signUpSecondary" path="/sign-up/secondary" component={SignUpSecondary} />
            <Route key="signUp" path="/sign-up" component={SignUpPrimaryNik} />
          </Switch>
        </RouteContainer>
      </PoseGroup>
    </Container>
  );
});
