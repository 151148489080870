import React from "react";
import { Dialog, DialogTitle, Box, IconButton, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  dialog: {
    padding: "40px",
  },
  dialogTitle: {
    padding: 0,
    "& h2": {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "24px",
    },
  },
  input: {
    minHeight: 96,
  },
  button: {
    width: 254,
    padding: "12px 0",
  },
  paper: {
    marginBottom: 8,
    padding: 10,
  },
}));

const initialState = {
  sharesAmount: "",
  numberMin: "",
  numberMax: "",
  errors: {},
};

export default function SecondarySuccessPopup({ onClose, open, cancellation }) {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography color="primary" variant="inherit">
          Successfully
        </Typography>
        <IconButton onClick={onClose} className={classes.closeButton} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box component="form" onSubmit={onClose}>
        <Box mt={3} display="flex">
            <Typography variant="h6">
                {cancellation ? `
                    Success!
                    Your offer has been cancelled.
                `
                : `
                    Thank you!
                    Your offer is sent for the approval. 
                    We will update you once it is listed.
                `
                }
            </Typography>
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Button onClick={onClose} className={classes.button} size="large" color="secondary" variant="contained">
            Ok
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
