import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  popup: {
    margin: "0 auto",
  },
  newsContainer: {
    display: "flex",
    marginTop: "20px",
  },
  newsImageContainer: {
    minWidth: "272px",
  },
  newsImage: {
    width: "100%",
    height: "180px",
    objectFit: "cover",
    background: "#c6c7c4",
    border: "1px solid #c6c7c4",
    borderRadius: "12px",
  },
  newsTextContainer: {
    paddingLeft: "20px",
    width: "100%"
  },
  newsTitle: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#2356B2",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  newsDescription: {
    paddingTop: "8px",
  },
  newsLink: {
    paddingTop: "8px",
    color: "rgb(147, 156, 176)",
  },
  newsSkeletonImage: {
    minWidth: 272,
    height: 180,
    background: "#f1f1f1",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
    "& > li": {
      borderRadius: "50%",
    },
  },
  articleImage: {
    width: "100%",
    height: "100%",
    background: "#939986",
    borderRadius: "12px",
    marginTop: "20px",
  },
  articleText: {
    paddingTop: 20,
  },
  radioGroupTabs: {
    display: "flex",
    border: "1px solid #2356B2",
    borderRadius: "24px",
    flexDirection: "row !important",
    justifyContent: "center !important",
  },
  radioGroupTabItem: {
    width: "30% !important",
    justifyContent: "center !important",
    borderLeft: "1px solid #2356B2",
    color: "rgb(126, 159, 193)",
    "&:first-child": {
      borderLeft: "unset"
    },
    "&[data-checked='true']": {
      color: "#2356B2",
      fontWeight: 500
    },
    "& span:first-child": {
      display: "none"
    },
    padding: "0.5rem 0"
  },
  noNewsText: {
    color: "rgb(126, 159, 193)",
    fontSize: "12px",
    marginLeft: "1rem"
  }
}));
